import CopyToClipboard from "react-copy-to-clipboard";
import styles from "../../../css/AssessmentForm.module.css";

export default function MedInputs({ title, name, value, handleChange }) {
  return (
    <>
      <div className={styles.lower_assess_medinput}>
        <span>{title}</span>
        <CopyToClipboard text={value} onCopy={(text) => text}>
          <span className={styles.lower_assess_button}>Copy</span>
        </CopyToClipboard>
      </div>
      <textarea name={name} value={value} onChange={handleChange}></textarea>
    </>
  );
}
