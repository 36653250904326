import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../utils/staticData";
import styles from "../../../css/UpperAssess.module.css";

export default function SupplementList({
  sup,
  prevDate,
  handleSupChange,
  handleRemove,
}) {
  return (
    <div className={styles.upper_assess_supplementlist}>
      <span>{`Supplement #${sup.supnum}`}</span>
      <input
        type="text"
        name="supplementList.supname"
        value={sup.supname}
        style={{ width: "20rem", marginLeft: "0.5rem", marginRight: "0.75rem" }}
        placeholder="Supplement Name and Amount"
        onChange={(e) => handleSupChange(e, sup.id)}
      />
      <span>Date Started:</span>
      <input
        type="date"
        name="supplementList.supdate"
        value={sup.supdate}
        style={{ marginLeft: "0.5rem", marginRight: "0.75rem" }}
        onChange={(e) => handleSupChange(e, sup.id)}
      />
      <span className={styles.list_addition_button}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.sup, sup.id)}
          style={{
            color: "#6e6d70",
            cursor: "pointer",
          }}
        />
      </span>
      {new Date(sup.supdate) > new Date(prevDate) && (
        <span style={{ color: "red" }}>Changed</span>
      )}
    </div>
  );
}
