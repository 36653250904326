import { v4 as uuid } from "uuid";

export const NESTED_IDS = {
  sup: "supplementList",
  allergy: "foodAllergy",
  intolerance: "foodIntolerance",
  inputfields: "inputFields",
  microinput: "micronutrientInputs",
  nutritionDiagnosis: "nutritionDiagnosis",
  nutritionRecs: "nutritionRecs",
};

export const WT_STATUS = [
  "Relatively Stable",
  "Slight Weight Loss",
  "Slight Weight Gain",
  "Weight Loss",
  "Weight Gain",
  "Significant Weight Loss",
  "Significant Weight Gain",
];

export const BWT_ROOMS = [
  "101-A",
  "101-B",
  "102-A",
  "102-B",
  "103-A",
  "103-B",
  "104-A",
  "104-B",
  "105-A",
  "105-B",
  "106-A",
  "106-B",
  "107-A",
  "107-B",
  "108-A",
  "108-B",
  "109-A",
  "109-B",
  "110-A",
  "110-B",
  "111-A",
  "111-B",
  "112-A",
  "112-B",
  "113-A",
  "113-B",
  "114-A",
  "114-B",
  "115-A",
  "115-B",
  "116-A",
  "116-B",
  "117-A",
  "117-B",
  "118-A",
  "118-B",
  "119-A",
  "119-B",
  "120-A",
  "120-B",
  "121-A",
  "121-B",
  "122-A",
  "122-B",
  "123-A",
  "124-B",
  "125-A",
  "125-B",
  "201-A",
  "201-B",
  "202-A",
  "202-B",
  "203-A",
  "203-B",
  "204-A",
  "204-B",
  "205-A",
  "205-B",
  "206-A",
  "206-B",
  "207-A",
  "207-B",
  "208-A",
  "208-B",
  "209-A",
  "209-B",
  "210-A",
  "210-B",
  "211-A",
  "211-B",
  "212-A",
  "212-B",
  "213-B",
  "214-A",
  "214-B",
  "215-A",
  "215-B",
  "216-A",
  "216-B",
  "217-A",
  "217-B",
  "218-A",
  "218-B",
  "219-A",
  "219-B",
  "220-A",
  "220-B",
  "221-A",
  "221-B",
  "222-A",
  "222-B",
  "223-A",
  "223-B",
  "224-A",
  "224-B",
  "225-A",
  "226-A",
  "227-A",
  "228-A",
  "229-A",
  "230-A",
  "301-A",
  "301-B",
  "302-A",
  "302-B",
  "303-A",
  "303-B",
  "304-A",
  "304-B",
  "305-A",
  "305-B",
  "306-A",
  "306-B",
  "307-A",
  "307-B",
  "308-A",
  "308-B",
  "309-A",
  "309-B",
  "310-A",
  "310-B",
  "311-A",
  "312-A",
  "313-A",
  "314-A",
  "315-A",
  "316-A",
  "317-A",
  "318-A",
  "319-A",
  "320-A",
  "321-A",
  "322-A",
  "322-B",
  "323-A",
  "324-A",
  "324-B",
  "326-A",
  "326-B",
  "328-A",
  "328-B",
];

export const WR_ROOMS = [
  "201-Cedar",
  "202-Cedar",
  "203-Cedar",
  "204-Cedar",
  "205-Cedar",
  "206-Cedar",
  "207-Cedar",
  "208-Cedar",
  "209-Cedar",
  "210-Cedar",
  "211-Cedar",
  "212-Cedar",
  "213-Cedar",
  "214-Cedar",
  "215-Cedar",
  "216-Cedar",
  "217-Cedar",
  "218-Cedar",
  "219-Cedar",
  "220-Cedar",
  "221-Cedar",
  "222-Cedar",
  "223-Cedar",
  "224-Cedar",
  "225-Cedar",
  "226-Cedar",
  "227-Cedar",
  "228-Cedar",
  "229-Cedar",
  "230-Cedar",
  "231-Cedar",
  "232-Cedar",
  "233-Cedar",
  "234-Cedar",
  "235-Cedar",
  "236-Cedar",
  "237-Cedar",
  "238-Cedar",
  "239-Cedar",
  "240-Juniper",
  "241-Juniper",
  "242-Juniper",
  "243-Juniper",
  "244-Juniper",
  "245-Juniper",
  "246-Juniper",
  "247-Juniper",
  "248-Juniper",
  "249-Juniper",
  "250-Juniper",
  "251-Juniper",
  "252-Juniper",
  "253-Juniper",
  "254-Juniper",
  "255-Juniper",
  "256-Juniper",
  "257-Juniper",
];

export const CAL_VALUES = {
  calFluidValslow: ["", 20, 25, 30, 35],
  calFluidValshigh: ["", 25, 30, 35, 40],
  proteinValslow: ["", 0.8, 1.0, 1.2],
  proteinvalshigh: ["", 1.0, 1.2, 1.5],
};

export const WT_CHANGE_TYPE = [
  "Relatively Stable Weight",
  "Slight Weight Loss",
  "Slight Weight Gain",
  "Weight Gain",
  "Weight Loss",
  "Significant Weight Gain",
  "Significant Weight Loss",
];

export const MULTI_OBJ = {
  activity: [
    { name: "none", number: "0" },
    { name: "ambulatory", number: "1.3" },
    { name: "bedrest", number: "1.2" },
  ],
  injury: [
    { name: "none", number: 0 },
    { name: "minor surgery", number: "1.1" },
    { name: "major surgery", number: "1.15" },
    { name: "mild infect", number: "1.1" },
    { name: "minor surgery", number: "1.1" },
    { name: "moderate infection", number: "1.3" },
    { name: "severe infection", number: "1.5" },
    { name: "skeletal trauma", number: "1.25" },
    { name: "blunt trauma", number: "1.15" },
    { name: "head trauma", number: "1.5" },
    { name: "<20% BSA burns", number: "1.25" },
    { name: "20-40% BSA burns", number: "1.6" },
    { name: ">50% BSA burns", number: "1.95" },
  ],
};

export const problemList = [
  "",
  "Inadequate/supboptimal energy intake",
  "Excessive energy intake",
  "Predicted suboptimal energy intake",
  "Predicted excessive energy intake",
  "Excessive oral intake",
  "Limited food acceptance",
  "Less than optimal enteral nutritional composition or modality",
  "Less than optimal parenteral nutritiona composition or modality",
  "Inadequate fluid intake",
  "Inconsistent carbohydrate intake",
  "Inadequate/suboptimal intake of calcium",
  "Predicted suboptimal nutrient intake",
  "Predicted excessive nutrient intake",
  "Swallowing difficulty",
  "Biting/Chewing (masticatory) difficulty",
  "Underweight",
  "Unintentional Weight Loss",
  "Overweight/Obesity",
  "Unintended weight gain",
  "Food- and nutrition-related knowledge deficit",
  "Not ready for diet/lifestyle change",
  "Limited adherence to nutrition-related recommendations",
  "Self-feeding difficulty",
  "Increased Nutrient Needs",
  "Alteration in GI function",
  "Malnutrition",
];

export const malnutritionRiskScreen = {
  A: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=Severe Decrease" },
    { id: uuid(), value: 1, title: "1=Moderate decrease in food intake" },
    { id: uuid(), value: 2, title: "2=No decrease in food intake" },
  ],
  B: [
    { id: uuid(), value: "", title: "" },
    {
      id: uuid(),
      value: 0,
      title: "0=Weight loss greater than 3 kg (6.6 lbs)",
    },
    { id: uuid(), value: 1, title: "1=Doesn't know" },
    {
      id: uuid(),
      value: 2,
      title: "2=Weight loss between 1 and 3 kg (2.2 and 6.6 lbs)",
    },
    { id: uuid(), value: 3, title: "3=No Weight Loss" },
  ],
  C: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=bed or chair bound" },
    {
      id: uuid(),
      value: 1,
      title: "1=able to get out of bed/chair but does not go out",
    },
    { id: uuid(), value: 2, title: "2=goes out" },
  ],
  D: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=yes" },
    { id: uuid(), value: 2, title: "2=no" },
  ],
  E: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=severe dementia or depression" },
    { id: uuid(), value: 1, title: "1=mild dementia" },
    { id: uuid(), value: 2, title: "2=no psychological problems" },
  ],
  F1: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=BMI less than 19" },
    { id: uuid(), value: 1, title: "1=BMI 19 to less than 21" },
    { id: uuid(), value: 2, title: "2=BMI 21 to less than 23" },
    { id: uuid(), value: 3, title: "3=BMI 23 or greater" },
  ],
  F2: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=CC less than 31" },
    { id: uuid(), value: 3, title: "2=CC 31 or greater" },
  ],
};

export const hardCodeArrays = {
  theraDiets: [
    "Regular diet",
    "Limited Concentrated Sweets diet",
    "No Added Salt diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "2 gm Sodium diet",
    "Gluten Restricted",
    "Full Liquid diet",
    "Clear Liquid diet",
    "Vegetarian diet",
    "Renal diet",
    "NPO",
  ],
  textMod: [
    "Regular texture",
    "Dysphagia Mechanical texture",
    "Finger Foods",
    "Mechanical Soft (Ground Meat) texture",
    "Mechanical Soft (Chopped Meat) texture",
    "Mechanical Soft (Puree Meat) texture",
    "Ground Meats",
    "Chopped Meats",
    "Puree texture",
  ],
  upperdent: [
    "Natural Teeth",
    "Full Upper",
    "Partial Upper",
    "Edentulous",
    "Missing Teeth",
  ],
  lowerdent: [
    "Natural Teeth",
    "Full Lower",
    "Partial Lower",
    "Edentulous",
    "Missing Teeth",
  ],
  intakeamt: ["", ">50%", "~50%", "<50%", "N/A"],
  liquidMod: ["Regular Liquids", "Nectar Liquids", "Honey Liquids"],
  eatSelfPerformance: [
    "",
    "Independent",
    "Supervision",
    "Limited Assistance",
    "Extensive Assistance",
    "Total Dependence",
    "Resident Refused",
    "N/A",
  ],
  eatSupportPerform: [
    "",
    "no setup",
    "setup help only",
    "one person physicial assistance",
    "resident refused",
  ],
  portionSize: ["", "Small", "Regular", "Large"],

  fluidRestriction: [
    "",
    "No Restriction",
    "500 mL",
    "750 mL",
    "1000 mL",
    "1200 mL",
    "1500 mL",
    "1800 mL",
    "2000 mL",
    "2500 mL",
  ],
};

export const DIETS = {
  cardiodiets: [
    "2 gm Sodium diet",
    "No Added Salt diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "Limited Concentrated Sweets/NAS diet",
  ],
  dmdiets: [
    "Limited Concentrated Sweets diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "Limited Concentrated Sweets/NAS diet",
  ],
  renaldiets: ["Renal diet"],
  glutendiets: ["Gluten Restricted"],
};

export const KEYWORDS = {
  cadKeyWords: [
    "CHF",
    "Congestive Heart Failure",
    "CAD",
    "HTN",
    "Stress-induced cardiomyopathy",
    "Atrial fibrillation",
    "Heart failure",
    "Edema",
    "Chronic lower extremity edema",
    "Chonric systolic heart failure",
    "CVA",
    "HFpEF",
  ],
  renalKeyWords: [
    "CKD Stage III",
    "Acute Kidney Injury",
    "CKD Stage IV",
    "CKD Stage V",
  ],
  dmKeyWords: ["Type II DM", "Type I DM", "prediabetes"],
  glKeyWords: ["Celiac's disease"],
};

export const THERADIETCHECKER = [
  "Regular diet",
  "Regular",
  "Liberal Geriatric (Regular) diet",
];

export const TEXTURECHECKER = ["Regular", "Regular Texture"];

export const letter = "abcdefghijklmnopqrstuvwxyz".split("");

export const tfs = [
  {
    name: "Jevity 1.2",
    calories: 1.2,
    protein: 0.0555,
    fluid: 0.807,
  },
  {
    name: "Jevity 1.5",
    calories: 1.5,
    protein: 0.0638,
    fluid: 0.76,
  },
  {
    name: "Osmolite 1.2",
    calories: 1.2,
    protein: 0.0555,
    fluid: 0.82,
  },
  {
    name: "Osmolite 1.5",
    calories: 1.5,
    protein: 0.0627,
    fluid: 0.762,
  },
  {
    name: "TwoCal HN",
    calories: 2.0,
    protein: 0.0835,
    fluid: 0.7,
  },
  {
    name: "Promote",
    calories: 1,
    protein: 0.0625,
    fluid: 0.839,
  },
  {
    name: "Vital 1.5",
    calories: 1.5,
    protein: 0.0675,
    fluid: 0.764,
  },
  {
    name: "Suplena Carb Steady",
    calories: 1.8,
    protein: 0.045,
    fluid: 0.738,
  },
  {
    name: "Pivot 1.5",
    calories: 1.5,
    protein: 0.0938,
    fluid: 0.759,
  },
  {
    name: "Nepro Carb Steady",
    calories: 1.8,
    protein: 0.081,
    fluid: 0.727,
  },
  {
    name: "Glucerna 1.2",
    calories: 1.2,
    protein: 0.06,
    fluid: 0.805,
  },
  {
    name: "Glucerna 1.5",
    calories: 1.5,
    protein: 0.0825,
    fluid: 0.759,
  },
  {
    name: "Replete",
    calories: 1,
    protein: 0.064,
    fluid: 0.84,
  },
  {
    name: "Liquid Hope",
    calories: 1.3,
    protein: 0.06479,
    fluid: 1.2761,
  },
  {
    name: "Nutren 1.5",
    calories: 1.5,
    protein: 0.068,
    fluid: 0.764,
  },
  {
    name: "Isosource 1.5",
    calories: 1.5,
    protein: 0.068,
    fluid: 0.764,
  },
];
