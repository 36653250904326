import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../utils/staticData";
import globalStyles from "../../../index.module.css";
import styles from "../../../css/DataEntry.module.css";

const NutritionRecs = ({ index, item, handleNestedChange, handleRemove }) => {
  return (
    <div className={styles.data_entry_rec_input}>
      <span>{index + 1}.</span>
      <input
        type="text"
        name="nutritionRecs.value"
        value={item.value}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span className={globalStyles.list_addition_button}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.nutritionRecs, item.id)}
          style={{
            color: "#6e6d70",
            cursor: "pointer",
          }}
        />
      </span>
    </div>
  );
};

export default NutritionRecs;
