import { ageCalc } from "./helperFunctions";

import { tfs } from "./staticData";

import {
  dateChange,
  selectUBW,
  percentChangeCalc,
  lbsChangeCalculation,
  nutrientCalc,
  equationCalculation,
  dietTypeChecker,
  vitaminRx,
  supplementRx,
  foodPrefsFunction,
  appetiteStimulationFunction,
  foodAllergiesFunction,
  mdsCAAgeneratorBMI,
  getTextureChecker,
  gettheraDietChecker,
  caaWtChangeProgressNote,
} from "./helperFunctions";

import { freeWaterFunction } from "./enteralHelperFunctions";

//CAA Generator
export function caaCreator(data) {
  const assessType = data.assessType;
  const caaBMI = mdsCAAgeneratorBMI(data) ? `${mdsCAAgeneratorBMI(data)}` : "";
  const textureMod =
    getTextureChecker(data) && data.texture?.toLowerCase() !== "regular"
      ? `On ${getTextureChecker(data)}.`
      : "";
  const theraDiet =
    data.theraDiet !== "Regular diet" && data.theraDiet
      ? `On ${data.theraDiet} secondary to ${gettheraDietChecker(data)}.`
      : "";
  const ivFluids = data.ivfluids
    ? `${data.ivfluids} per hospital documentation.`
    : "";

  const wtChange = caaWtChangeProgressNote(data).map((item) =>
    item ? `${item.notedchange} secondary to ${item.reason}.` : ""
  );
  const caaData = [caaBMI, theraDiet, textureMod, ivFluids, ...wtChange];
  const mappedCaaData = caaData
    .filter((elm) => elm)
    .map((item) => `<li>${item}</li>`);

  const rawCAA = caaData.filter((elm) => elm).join(" ");

  const formattedCaa = rawCAA
    ? `---------------------------<br>CAA----Nutrition (#12)<ol>${mappedCaaData.join(
        ""
      )}</ol>`
    : "";
  if (
    assessType === "Admission" ||
    assessType === "Significant Change" ||
    assessType === "Readmission" ||
    assessType === "Annual"
  ) {
    return { raw: rawCAA, formatted: formattedCaa };
  } else {
    return "";
  }
}

///Developmenet of Nutrition Treatment Progress Piece
export function nutritionRxProgNote(data) {
  const supplementList = supplementRx(data).map((item) => item.total);
  const foodAllergy = foodAllergiesFunction(data).allergy.map((item) => item);
  const foodIntol = foodAllergiesFunction(data).intolerance.map((item) => item);

  let allergyCombined;

  if (foodAllergy.length > 0 && foodIntol.length > 0) {
    allergyCombined = `Food Allergy to ${foodAllergy.join(
      ", "
    )} and food intolerance to ${foodIntol.join(
      ", "
    )} will be noted in electronic dietary profile, tray ticket, and electronic medical record`;
  } else if (foodAllergy.length > 0) {
    allergyCombined = `Food Allergy to ${foodAllergy.join(
      ", "
    )} will be noted in electronic dietary profile, tray ticket, and electronic medical record`;
  } else if (foodIntol.length > 0) {
    allergyCombined = `Food Infolerance to ${foodIntol.join(
      ", "
    )} will be noted in electronic dietary profile, tray ticket, and electronic medical record`;
  } else {
    allergyCombined = "";
  }

  let weightOrder;
  if (data.weightOrder === "d/c") {
    weightOrder =
      "Weights have been discontinued secondary to resident not wishing to apply interventions for loss or gain";
  } else {
    weightOrder = data.weightOrder;
  }

  const nutritionRxCombined = [
    dietTypeChecker(data),
    vitaminRx(data),
    supplementList.join(" / "),
    foodPrefsFunction(data),
    weightOrder,
    data.selectMenu && `Selective menu`,
    data.adaptEquip,
    appetiteStimulationFunction(data),
    allergyCombined && allergyCombined,
  ];

  const nutritionRxNumbered = nutritionRxCombined
    .filter((item) => item)
    .map((item) => `<li>${item}</li>`);

  if (nutritionRxNumbered.length > 1) {
    return `---------------------------<br><span>Nutrition Rx:</span><ol>${nutritionRxNumbered
      .join("")
      .replaceAll("=>", "&#10132")}</ol>`;
  } else {
    return "";
  }
}

///Developmenet of Nutrition Diagnosis Progress Piece
export function nutritionDxProgNote(data) {
  const nutritionDx = [];

  data.nutritionDiagnosis.forEach((item) => {
    if (item.problem && item.etiology && item.symptom) {
      nutritionDx.push(
        `<li>${item.problem} secondary to ${item.etiology} as evidenced by ${item.symptom}</li>`
      );
    }
  });

  if (nutritionDx.length > 0) {
    return `---------------------------<br><span>Nutrition Dx:</span><ul>${nutritionDx.join(
      ""
    )}</ul>`;
  } else {
    return "";
  }
}

export function nutNeedsProgNote(data) {
  const calc = nutrientCalc(data);
  const age = ageCalc(data.age);
  const equations = equationCalculation(data, age, data.sex);
  const nutNeedsList = [];

  if (data.calneedslow && data.calneedshigh) {
    nutNeedsList.push(
      `<li>Calorie Needs: ${calc.nutrientCalories} (${data.calneedslow} - ${data.calneedshigh}) Calories/kg</li>`
    );
  }
  if (data.proneedslow && data.proneedshigh) {
    nutNeedsList.push(
      `<li>Protein Needs: ${calc.nutrientProtein} (${data.proneedslow} - ${data.proneedshigh}) protein/kg</li>`
    );
  }
  if (data.fluidneedslow && data.fluidneedshigh) {
    nutNeedsList.push(
      `<li>Calorie Needs: ${calc.nutrientFluids} (${data.fluidneedslow} - ${data.fluidneedshigh}) mL/kg</li>`
    );
  }

  if (nutNeedsList.length > 0) {
    return `---------------------------<br><span>NUTRITIONAL NEEDS:</span><ul>${nutNeedsList.join(
      ""
    )}</ul><br>&#10070 (Calorie Reference #1) Mifflin-St Jeor Equation: ${Math.round(
      equations.mfsEquation
    )} kcal [Resting Metabolic Rate * Activity Factor (${data.actFactor})${
      data.injFactor ? ` * Injury Factor (${data.injFactor})` : ""
    }]<br>&#10070 (Calorie Reference #2) Livingston Equation: ${Math.round(
      equations.livEquation
    )} kcal [Resting Metabolic Rate * Activity Factor (${data.actFactor})${
      data.injFactor ? `*  Injury Factor (${data.injFactor})` : ""
    }]<br>`;
  } else {
    return "";
  }
}

export function ubwRangeCheckerProgNote(data) {
  const ubwStart = data.ubwLow;
  const ubwEnd = data.ubwHigh;
  const wt = data.wt;
  const ubwMonths = data.ubwMonths;
  const ubwPercentage = selectUBW(wt, ubwStart, ubwEnd);

  let ubwLevel;
  if (ubwPercentage === 0.0) ubwLevel = "within";
  if (ubwPercentage < 0.0) ubwLevel = "below";
  if (ubwPercentage > 0.0) ubwLevel = "above";

  if (ubwStart && ubwEnd) {
    return `Usual Body Weight Range: (${ubwStart} - ${ubwEnd} lbs) &#10132 (Current weight is ${
      ubwPercentage !== 0.0 ? `${ubwPercentage.toFixed(1)}%` : ""
    } ${ubwLevel} usual body weight range )<br> Usual Body Weight Range is Average Weight Range (+/- 10 lbs) within the past ${ubwMonths} months.<br>`;
  } else {
    return "";
  }
}

export function wtChangeProgressNote(data) {
  const wtChangeData = data.wtChange;
  const wtChangeList = [];

  wtChangeData.forEach((wt) => {
    if (wt.toggle) {
      if (wt.wtid === "1") {
        wtChangeList.push(
          `<li>1 Month Weight Change: (${new Date(wt.wtdate).toLocaleDateString(
            "en"
          )}) ${wt.wtchange} lbs. [ ${lbsChangeCalculation(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)} lbs. / ${percentChangeCalc(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)}% @ ${dateChange(
            data.currWtDate,
            wt.wtdate
          )} days ]</li>`
        );
      }
      if (wt.wtid === "3") {
        wtChangeList.push(
          `<li>3 Month Weight Change: (${new Date(wt.wtdate).toLocaleDateString(
            "en"
          )}) ${wt.wtchange} lbs. [ ${lbsChangeCalculation(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)} lbs. / ${percentChangeCalc(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)}% @ ${dateChange(
            data.currWtDate,
            wt.wtdate
          )} days ]</li>`
        );
      }
      if (wt.wtid === "6") {
        wtChangeList.push(
          `<li>6 Month Weight Change: (${new Date(wt.wtdate).toLocaleDateString(
            "en"
          )}) ${wt.wtchange} lbs. [ ${lbsChangeCalculation(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)} lbs. / ${percentChangeCalc(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)}% @ ${dateChange(
            data.currWtDate,
            wt.wtdate
          )} days ]</li>`
        );
      }
    }
  });

  if (wtChangeList.length > 0) {
    return `<span>WEIGHT CHANGES:</span><ul>${wtChangeList.join("")}</ul>`;
  } else {
    return "";
  }
}

export function labProgNote(data) {
  const labs = data.labs;
  const sexValue = data.sex;

  const labList = [];

  labs.forEach((lab) => {
    const dateChange =
      lab.date && `(${new Date(lab.date).toLocaleDateString("en")})`;
    let highLowRange;
    let refRange;
    let labType;

    if (lab.range === "low") {
      highLowRange = `(&#x1F82B)`;
    } else if (lab.range === "High") {
      highLowRange = `(&#x1F829)`;
    } else if (lab.range === "Extra Low") {
      highLowRange = `(&#x1F82B &#x1F82B)`;
    } else if (lab.range === "Extra High") {
      highLowRange = `(&#x1F829 &#x1F829;)`;
    } else {
      highLowRange = "";
    }

    if (lab.name === "Na" && lab.value) {
      refRange = " &#9755; (Reference Range: 135 - 145 mmol/L)";
      labType = "mmol/L";
    } else if (lab.name === "Alb" && lab.value) {
      refRange = " &#9755; (Reference Range: 3.4 - 5.4 g/dL)";
      labType = "g/dL";
    } else if (lab.name === "K" && lab.value) {
      refRange = " &#9755; (Reference Range: 3.5 - 5.0 mEq/L)";
      labType = "mEq/L";
    } else if (lab.name === "BUN" && lab.value) {
      refRange = " &#9755; (Reference Range: 5.0 - 20.9 mg/dL)";
      labType = "mg/dL";
    } else if (lab.name === "Phos" && lab.value) {
      refRange = " &#9755; (Reference Range: 2.8 - 4.5 mg/dL)";
      labType = "mg/dL";
    } else if (lab.name === "Mag" && lab.value) {
      refRange = " &#9755; (Reference Range: 1.3 - 2.1 mg/dL)";
      labType = "mg/dL";
    } else if (lab.name === "Creat" && sexValue === "male" && lab.value) {
      refRange = " &#9755; (Reference Range: 0.7 - 1.39 mg/dL)";
      labType = "mg/dL";
    } else if (lab.name === "Creat" && sexValue === "female" && lab.value) {
      refRange = " &#9755; (Reference Range: 0.6 - 1.19 mg/dL)";
      labType = "mg/dL";
    } else {
      refRange = "";
    }
    labList.push([
      dateChange,
      lab.name,
      lab.value,
      labType,
      highLowRange,
      refRange,
    ]);
  });

  const lab3 = [];

  labList.forEach((l) => {
    const lab2 = l.filter((elm) => elm);
    if (lab2.length > 2) {
      lab3.push(`<li>${lab2.join(" ")}</li>`);
    }
  });

  if (lab3.length > 0) {
    return `---------------------------<br><span>LABS:</span>
    <ul>${lab3.join("")}</ul>`;
  } else {
    return "";
  }
}

export function supplementSplitter(data) {
  const supplementList = data.supplementList;
  const prevDate = data.prevDate;

  const supplementListData = [];

  supplementList.forEach((s) => {
    const changedSup =
      new Date(s.supdate) > new Date(prevDate)
        ? "(Changed from previous assessment)"
        : "";
    if (s.supname && s.supdate) {
      supplementListData.push(
        `<li>${s.supname} &#10132 ${new Date(s.supdate).toLocaleDateString(
          "en"
        )} ${changedSup}</li>`
      );
    }
  });

  if (supplementListData.length > 0) {
    return `<span>SUPPLEMENTS:</span><ul>${supplementListData.join("")}</ul>`;
  } else {
    return "";
  }
}

//combined Food Allergy/Intolerance Progress Note
export function foodProgressNote(data) {
  const foodList = data.type;

  if (foodList) return foodList;
  if (!foodList) return `${data.name}`;
}

export function textAreaDivider(data) {
  const textValue = data.type;

  const textValueA = textValue
    .split(", ")
    .filter((elm) => elm)
    .map((item) => `<li> ${item}</li>`);

  if (textValue.length > 0)
    return `---------------------------<br><span>${data.name}:</span>
    <ul>${textValueA.join("")}</ul>`;
  return "";
}

export function dietPTA(data) {
  const assessType = data.assessType;
  const admitLocal = data.admitLocal;
  const dietPrior = data.dietPrior;

  if (dietPrior && assessType === "Admission") {
    return `Diet @ ${admitLocal}: ${dietPrior}<br>`;
  } else if (dietPrior && assessType) {
    return `Diet @ ${admitLocal}: Unknown<br>`;
  } else {
    return "";
  }
}

export function hospitalDxProgNote(data) {
  const assess = data.assessType;
  const diagnosis = data.admitType;
  const admitFrom = data.admitLocal;
  const location = data.location;

  if (assess === "Admission" && diagnosis && location === "bwt")
    return `Admitted From: ${admitFrom}<br>Hospital Diagnosis: ${diagnosis}<br>`;
  if (assess === "Readmission" && diagnosis && location === "bwt")
    return `Readmitted From: ${admitFrom}<br>Hospital Diagnosis: ${diagnosis}<br>`;
  if (assess === "PRN" && diagnosis) return `Reason: ${diagnosis}<br>`;
  if (assess === "Significant Change" && diagnosis)
    return `Reason: ${diagnosis}<br>`;
  if (assess === "Admission" && diagnosis && location === "wr")
    return `Admit Diagnosis: ${diagnosis}<br>`;
  return "";
}

export function enteralFlushes(data) {
  const water = freeWaterFunction(data);

  const totalFlushVolume =
    water.freeWaterFlush > 0
      ? `${water.freeWaterFlush} mL with water flushes (number of flushes: ${data.freeFlush.freeTimes}) / amount of each flush: ${data.freeFlush.freeRate}`
      : "";
  const totalMedVolume =
    water.medWaterFlush > 0
      ? `${water.medWaterFlush} mL with medication flushes (number of flushes: ${data.medFlush.medTimes} / amount of each flush: ${data.medFlush.medRate})`
      : "";
  const totalBolusVolume =
    water.bolusWaterFlush > 0
      ? `${water.bolusWaterFlush} mL with bolus flushes (number of flushes: ${data.bolusFlush.bolusTimes} / amount of each flush: ${data.bolusFlush.bolusRate})`
      : "";

  const total = [totalFlushVolume, totalMedVolume, totalBolusVolume];

  return total.filter((elm) => elm).join("<br>");
}

export function enteralProgressNote(data) {
  const foundTF = tfs.find((item) => item.name === data.tfType);
  const tfVolume = Number(data.tfRate) * Number(data.tfPerDay);
  const tfBoolean = data.enteralBoolean;

  const nutrient = nutrientCalc(data);
  const water = freeWaterFunction(data);

  const calories = foundTF ? foundTF.calories * tfVolume : "";
  const protein = foundTF ? foundTF.protein * tfVolume : "";
  const fluid = foundTF ? foundTF.fluid * tfVolume : "";
  const calneedslow = Number(nutrient.calLow);
  const calneedshigh = Number(nutrient.calHigh);
  const proneedslow = Number(nutrient.proLow);
  const proneedshigh = Number(nutrient.proHigh);
  const fluidneedslow = Number(nutrient.fluidLow);
  const fluidneedshigh = Number(nutrient.fluidHigh);

  const totalFluid = [
    fluid,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const total = [
    tfVolume,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const totalFluidVolume = totalFluid.reduce((a, b) => a + b, 0);
  const totalvolume = total.reduce((a, b) => a + b, 0);

  let tfPercentCalc;
  let tfPercentPro;
  let tfPercentFluid;
  if (calories < calneedslow) {
    tfPercentCalc = (calories / calneedslow) * 100;
  } else if (calories > calneedshigh) {
    tfPercentCalc = (calories / calneedshigh) * 100;
  } else if (
    calneedslow &&
    calories >= calneedslow &&
    calories <= calneedshigh
  ) {
    tfPercentCalc = 0;
  } else {
    tfPercentCalc = "";
  }

  if (protein < proneedslow) {
    tfPercentPro = (protein / proneedslow) * 100;
  } else if (protein > proneedshigh) {
    tfPercentPro = (protein / proneedshigh) * 100;
  } else if (proneedslow && protein >= proneedslow && protein <= proneedshigh) {
    tfPercentPro = 0;
  } else {
    tfPercentPro = "";
  }

  if (totalFluidVolume < fluidneedslow) {
    tfPercentFluid = (totalFluidVolume / fluidneedslow) * 100;
  } else if (totalFluidVolume > fluidneedshigh) {
    tfPercentFluid = (totalFluidVolume / fluidneedshigh) * 100;
  } else if (
    fluidneedslow &&
    totalFluidVolume >= fluidneedslow &&
    totalFluidVolume <= fluidneedshigh
  ) {
    tfPercentFluid = 0;
  } else {
    tfPercentFluid = "";
  }

  if (tfBoolean) {
    return `---------------------------<br>
    ENTERAL NUTRITION<br>
    Tube Feeding Order: ${data.tfType} @ ${data.tfRate} mL<br>
    Flushes:<br>
    ${enteralFlushes(data)}<br>
    Total Volume Formula: ${tfVolume} mL<br>
    Total Volume (Formula & Flushes): ${totalvolume} mL<br>
    Calories Provided: ${Math.round(calories)} kcal/day (${
      tfPercentCalc > 0 ? Math.round(tfPercentCalc) : "100"
    }% of Calorie Needs)<br>
    Protein Provided: ${Math.round(protein)} gm/day (${
      tfPercentPro > 0 ? Math.round(tfPercentPro) : "100"
    }% of Protein Needs)<br>
    Fluids Provided: ${totalFluidVolume} mL/day (${
      tfPercentFluid > 0 ? Math.round(tfPercentPro) : "100"
    }% of Fluid Needs)<br>
    ---------------------------<br>`;
  } else {
    return "";
  }
}
