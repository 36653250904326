import React, { useEffect, useState } from "react";
import { labCalc } from "../../../utils/helperFunctions";

const Labs = ({ item, inputs, handleNestedChange, handleNestedLabChange }) => {
  const [labChange, setLabChange] = useState("");

  useEffect(() => {
    let labCheck;
    const delayFunction = setTimeout(() => {
      labCheck = labCalc(item.name, Number(item.value), inputs.sex);
      setLabChange(labCheck);
    }, 500);

    return () => clearTimeout(delayFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.value]);

  useEffect(() => {
    handleNestedLabChange(item.id, labChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labChange]);

  return (
    <>
      <span>{item.name}:</span>
      <input
        type="text"
        name="labs.value"
        value={item.value}
        style={{ width: "2.5rem" }}
        onChange={(e) => handleNestedChange(e, item.id, labChange)}
      />
      <input
        type="date"
        name="labs.date"
        value={item.date}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span style={{ marginLeft: "5px" }}>
        <strong>{labChange}</strong>
      </span>
    </>
  );
};

export default Labs;
