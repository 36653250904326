import styles from "../css/Assessment.module.css";
import globalStyles from "../index.module.css";
import { useState } from "react";
import { useGetItemByIDQuery } from "../app/api/listApiSlice.js";
import { useParams, useNavigate } from "react-router-dom";

import Nav from "../components/navigation/Nav.jsx";
import AssessmentForm from "../features/assessments/AssessmentForm.jsx";
import Modal from "../features/assessments/modal/Modal.jsx";
import DataEntryForm from "../features/assessments/data-entry/DataEntry.jsx";
import AssessForm from "../components/wrappers/AssessForm.js";
import NavForm from "../components/wrappers/NavForm.js";
import WhiteButton from "../components/buttons/WhiteButton.js";
import MalnutritionRisk from "../features/assessments/malnutrition-risk/MalnutritionRisk.jsx";
import AppLogout from "../app/auth/AppLogout.js";
import Spinner from "../components/loaders/Spinner.jsx";

export default function Assessment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [enteralToggle, setEnteralToggle] = useState(false);
  const [modal, setModal] = useState({
    summary: false,
    progress: false,
    enteral: false,
  });

  const {
    data: idData,
    isLoading: singLoading,
    isSuccess: singSuccess,
    isError: singIsError,
    error: singError,
  } = useGetItemByIDQuery(id, {
    pollingInterval: 15000,
    refetchOnMountOrArgChange: true,
  });

  function openModal(e) {
    const name = e.target.name;
    if (name === "Summary") {
      setModal((prev) => ({ ...prev, summary: true }));
    }
    if (name === "Progress Note") {
      setModal((prev) => ({ ...prev, progress: true }));
    }
    if (name === "Enteral") {
      setModal((prev) => ({ ...prev, enteral: true }));
    }
    document.body.style.overflow = "hidden";
  }

  function openPDF() {
    navigate(`/malnutritionscreen/${id}`, { state: idData });
  }

  function closeModal(name) {
    if (name === "Summary") {
      setModal((prev) => ({ ...prev, summary: false }));
    }
    if (name === "Progress Note") {
      setModal((prev) => ({ ...prev, progress: false }));
    }
    if (name === "Enteral") {
      setModal((prev) => ({ ...prev, enteral: false }));
    }
    document.body.style.overflow = "unset";
  }

  function handleEnteralToggle(value) {
    setEnteralToggle(value);
  }

  let content;

  if (singLoading) {
    content = <Spinner />;
  }

  if (singSuccess) {
    content = (
      <div className={globalStyles.body_wrapper}>
        {modal.summary && (
          <div>
            <Modal modal={modal} data={idData} closeModal={closeModal} />
          </div>
        )}
        {modal.progress && (
          <div>
            <Modal modal={modal} data={idData} closeModal={closeModal} />
          </div>
        )}
        {modal.enteral && (
          <div>
            <Modal modal={modal} data={idData} closeModal={closeModal} />
          </div>
        )}
        <AppLogout>
          <div>
            <Nav />
          </div>
          <div>
            <NavForm />
          </div>
          <div className={styles.top_bar}>
            <WhiteButton name="Summary" event={openModal} />
            <WhiteButton name="Progress Note" event={openModal} />
            {idData.location === "bwt" && (
              <WhiteButton name="Malnutrition Risk Report" event={openPDF} />
            )}
            {enteralToggle && <WhiteButton name="Enteral" event={openModal} />}
            <WhiteButton name="Parenteral" />
          </div>
          <div className={styles.main_body}>
            <div className={styles.outer_assess_form}>
              <AssessForm>
                <AssessmentForm
                  data={idData}
                  key={idData._id}
                  handleEnteralToggle={handleEnteralToggle}
                />
              </AssessForm>
            </div>
            <div className={styles.outer_right_assess_form}>
              <AssessForm
                type="Data Entry"
                styleWidth={{ width: "35rem" }}
                stylePadding={{
                  padding: "0px",
                  marginTop: "2rem",
                  width: "50rem",
                }}>
                <DataEntryForm data={idData} key={idData._id} />
              </AssessForm>
              {idData.location === "bwt" && (
                <AssessForm
                  type="Malnutrition Risk Screen"
                  styleWidth={{ width: "35rem" }}
                  stylePadding={{ padding: "0px", marginTop: "2rem" }}>
                  <MalnutritionRisk data={idData} key={idData._id} />
                </AssessForm>
              )}
            </div>
          </div>
        </AppLogout>
      </div>
    );
  }

  if (singIsError) {
    content = (
      <div className={globalStyles.body_wrapper}>
        <div>
          <Nav />
        </div>
        <AssessForm>
          <p>{singError?.idData?.message}</p>
        </AssessForm>
      </div>
    );
  }

  return content;
}
