import React from "react";
import styles from "../../../css/SummaryModal.module.css";
import globalStyles from "../../../index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  nutritionSummaryFunction,
  nutritionRxFunction,
  malnutritionRiskFunction,
  nutritionRecsFunction,
} from "../../../utils/summaryHelperFunctions";
import CopyToClipboard from "react-copy-to-clipboard";

const SummaryModal = ({ data, closeModal }) => {
  const nutritionSummary = nutritionSummaryFunction(data);
  const nutritionRx = nutritionRxFunction(data);
  const malnutritionRisk = malnutritionRiskFunction(data).malName;
  const malnutritionCount = malnutritionRiskFunction(data).total.num;
  const nutritionRecs = nutritionRecsFunction(data);

  const unit = data.room.split("-")[1];

  const carePlanNoteFocus = `${data.firstName} is at risk for alterations in nutritional status
      secondary to ${data.admitType}
    `;
  let carePlanNoteGoal;
  if (data.foodIntake === ">50%") {
    carePlanNoteGoal = `${data.firstName} will continue with >50% average meal consumption.`;
  }
  if (data.foodIntake === "~50%" || data.foodIntake === "<50%") {
    carePlanNoteGoal = `${data.firstName} will have with >50% average meal consumption.`;
  }

  const dietCheck = `Provide me with ${data.theraDiet}.`;
  const addDietPrefs = `Incorporate food prefs per dietary profile found in ${unit} kitchen area${
    unit.toLowerCase() === "cedar" ? ` and in ${unit} nurses station` : "."
  }`;
  const adaptEquip = `Provide adaptive equipment: ${data.adaptEquip}.`;
  const poIntake = `Record & monitor my PO intake at meals.`;
  const poLocation = `Provide meals in location of my choosing.`;
  const poAlt = `Provide me with an alternative if I don't like what is being served.`;
  const wtOrder = `Obtain/record/monitor my weights ${data.weightOrder.toLowerCase()}. Unless specified differently by physician's order.`;

  const location = data.location;
  let content;
  if (location === "bwt") {
    content = (
      <div style={{ width: "35rem" }}>
        <div className={styles.summary_modal_title_row}>
          <h4 className={styles.summary_modal_title}>Nutrition Summary</h4>
          <CopyToClipboard text={nutritionSummary} onCopy={(text) => text}>
            <button className={styles.button}>copy</button>
          </CopyToClipboard>
        </div>
        <p className={globalStyles.global_font_format}>{nutritionSummary}</p>
        <div className={styles.summary_modal_title_row}>
          <h4 className={styles.summary_modal_title_row}>Nutrition Rx</h4>
          <CopyToClipboard text={nutritionRx} onCopy={(text) => text}>
            <button className={styles.button}>copy</button>
          </CopyToClipboard>
        </div>
        <p className={globalStyles.global_font_format}>{nutritionRx}</p>
        <div className={styles.summary_modal_title_row}>
          <h4 className={styles.summary_modal_title_row}>
            Malnutrition Risk Screen
          </h4>
          <CopyToClipboard text={malnutritionRisk} onCopy={(text) => text}>
            <button className={styles.button}>copy</button>
          </CopyToClipboard>
        </div>
        {malnutritionCount > 0 && (
          <p className={globalStyles.global_font_format}>{malnutritionRisk}</p>
        )}
        <div className={styles.summary_modal_title_row}>
          <h4 className={styles.summary_modal_title_row}>Recommendations</h4>
          <CopyToClipboard text={nutritionRecs} onCopy={(text) => text}>
            <button className={styles.button}>copy</button>
          </CopyToClipboard>
        </div>
        <p className={globalStyles.global_font_format}>{nutritionRecs}</p>
      </div>
    );
  }

  if (location === "wr") {
    content = (
      <div style={{ width: "35rem" }}>
        <div className={styles.summary_modal_title_row}>
          <h4 className={styles.summary_modal_title}>Nutrition Summary</h4>
          <CopyToClipboard text={nutritionSummary} onCopy={(text) => text}>
            <button className={styles.button}>copy</button>
          </CopyToClipboard>
        </div>
        <p className={globalStyles.global_font_format}>{nutritionSummary}</p>
        <div>
          <h4 className={styles.summary_modal_title}>Nutrition Care Plan</h4>
          <div className={styles.summary_modal_copy_focus}>
            <h3 className={styles.summary_modal_subtitle}>FOCUS</h3>
            <CopyToClipboard text={carePlanNoteFocus} onCopy={(text) => text}>
              <button className={styles.button}>copy</button>
            </CopyToClipboard>
          </div>
          <p
            className={globalStyles.global_font_format}
            style={{ marginTop: "5px" }}>
            {carePlanNoteFocus}
          </p>
          <div className={styles.summary_modal_copy_focus}>
            <h3 className={styles.summary_modal_subtitle}>Goal</h3>
            <CopyToClipboard text={carePlanNoteGoal} onCopy={(text) => text}>
              <button className={styles.button}>copy</button>
            </CopyToClipboard>
          </div>
          <p
            className={globalStyles.global_font_format}
            style={{ marginTop: "5px" }}>
            {carePlanNoteGoal}
          </p>
          <h3 className={styles.summary_modal_subtitle}>Interventions</h3>
          <ul>
            {data.theraDiet && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {dietCheck}
                  <CopyToClipboard text={dietCheck} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li
                    className={globalStyles.global_font_format}
                    style={{ padding: "0" }}>
                    (LNA, Nutrition Service Mananger, Dietary Aide)
                  </li>
                </ul>
              </>
            )}
            {data.inputFields && (
              <>
                <li className={styles.summary_modal_list_item}>
                  <span style={{ width: "28rem" }}>{addDietPrefs}</span>
                  <CopyToClipboard text={addDietPrefs} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li className={globalStyles.global_font_format}>
                    (LNA, Nutrition Service Mananger, Dietary Aide)
                  </li>
                </ul>
              </>
            )}
            {data.adaptEquip && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {adaptEquip}
                  <CopyToClipboard text={adaptEquip} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li className={globalStyles.global_font_format}>
                    (LNA, Nutrition Service Mananger, Dietary Aide)
                  </li>
                </ul>
              </>
            )}
            {data.foodIntake && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {poIntake}
                  <CopyToClipboard text={poIntake} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li
                    className={globalStyles.global_font_format}
                    style={{ padding: "0" }}>
                    (LNA, Dietitian, LPN, RN)
                  </li>
                </ul>
              </>
            )}
            {data.foodIntake && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {poLocation}
                  <CopyToClipboard text={poLocation} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li
                    className={globalStyles.global_font_format}
                    style={{ padding: "0" }}>
                    (LNA, Dietary Aide, Nutrition Service Manager)
                  </li>
                </ul>
              </>
            )}
            {data.foodIntake && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {poAlt}
                  <CopyToClipboard text={poAlt} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li
                    className={globalStyles.global_font_format}
                    style={{ padding: "0" }}>
                    (LNA, Dietary Aide, Nutrition Service Manager)
                  </li>
                </ul>
              </>
            )}
            {data.wt && (
              <>
                <li className={styles.summary_modal_list_item}>
                  {wtOrder}
                  <CopyToClipboard text={wtOrder} onCopy={(text) => text}>
                    <button className={styles.button}>copy</button>
                  </CopyToClipboard>
                </li>
                <ul>
                  <li
                    className={globalStyles.global_font_format}
                    style={{ padding: "0" }}>
                    (Dietitian, LNA, LPN, RN)
                  </li>
                </ul>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={styles.summary_modal_top_close}>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Summary")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      {content}
    </div>
  );
};

export default SummaryModal;
