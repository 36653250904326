import styles from "../../../css/DataEntry.module.css";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyToClipboard from "react-copy-to-clipboard";

export default function DataEntryLabs({ lab }) {
  const labText = `${lab.value} (${
    lab.date &&
    new Date(lab.date).toLocaleDateString("en-US", { timeZone: "UTC" })
  })`;

  return (
    <div className={lab.date && styles.data_entry_div}>
      {lab.value && lab.date ? (
        <>
          <span className={styles.data_entry_labs}>{lab.name}:</span>
          <span>
            {labText}
            {lab.range === "high" && (
              <FontAwesomeIcon
                icon={faArrowUp}
                size="sm"
                style={{ color: "#080808", marginLeft: "0.3rem" }}
              />
            )}
            {lab.range === "very high" && (
              <>
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size="sm"
                  style={{ color: "#080808", marginLeft: "0.3rem" }}
                />
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size="sm"
                  style={{ color: "#080808" }}
                />
              </>
            )}
            {lab.range === "low" && (
              <FontAwesomeIcon
                icon={faArrowUp}
                size="sm"
                rotation={180}
                style={{ color: "#080808", marginLeft: "0.3rem" }}
              />
            )}
            {lab.range === "very low" && (
              <>
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size="sm"
                  rotation={180}
                  style={{ color: "#080808", marginLeft: "0.3rem" }}
                />
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size="sm"
                  rotation={180}
                  style={{ color: "#080808" }}
                />
              </>
            )}
          </span>
          <CopyToClipboard text={labText} onCopy={(text) => text}>
            <button className={styles.data_entry_copy_button}>Copy</button>
          </CopyToClipboard>
        </>
      ) : (
        <span></span>
      )}
    </div>
  );
}
