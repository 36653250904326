import styles from "../../../css/SummaryModal.module.css";
import SummaryModal from "./SummaryModal";
import ProgressModal from "./ProgressModal";
import EnteralModal from "./EnteralModal";

export default function Modal({ modal, data, closeModal }) {
  return (
    <div className={styles.modal_outer}>
      <div className={styles.modal_inner}>
        {modal.summary && <SummaryModal data={data} closeModal={closeModal} />}
        {modal.progress && (
          <ProgressModal data={data} closeModal={closeModal} />
        )}
        {modal.enteral && <EnteralModal data={data} closeModal={closeModal} />}
      </div>
    </div>
  );
}
