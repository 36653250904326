import Nav from "../components/navigation/Nav.jsx";
import ListMaker from "../features/startpage/ListMaker.jsx";
import ToDoMaker from "../features/startpage/ToDoMaker.jsx";
import LowerNav from "../components/navigation/LowerNav.jsx";
import globalStyles from "../index.module.css";
import AppLogout from "../app/auth/AppLogout.js";

export default function App() {
  return (
    <div className={globalStyles.body_wrapper}>
      <AppLogout>
        <Nav />
        <ListMaker />
        <LowerNav />
        <ToDoMaker />
      </AppLogout>
    </div>
  );
}
