import { React, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerify2faMutation } from "../app/api/authAPISlice";

import globalStyles from "../index.module.css";
import styles from "../css/TwoFA.module.css";
import { setCredentials } from "../app/auth/authSlice";

const TwoFA = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [token, setToken] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const inputRef = useRef(null);

  useEffect(() => {
    setErrMsg("");
  }, [token]);

  const combinedToken = Object.values(token).join("");

  const [verify2fa] = useVerify2faMutation();

  const handleTokenInput = (e) => {
    const { name, value } = e.target;

    setToken((prev) => ({ ...prev, [name]: value }));
  };

  const handleClear = (e) => {
    setToken({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
    });
    inputRef.current.focus();
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const res = await verify2fa({
        userId: data,
        token: combinedToken,
      }).unwrap();
      const accessToken = res.accessToken;
      dispatch(setCredentials({ accessToken: accessToken }));
      setToken({
        first: "",
        second: "",
        third: "",
        fourth: "",
        fifth: "",
        sixth: "",
      });
      navigate("/dashboard");
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 404) {
        setErrMsg("User does not exist");
      } else if (err.status === 401) {
        setErrMsg("Unathorized");
      }
    }
  };
  return (
    <div className={globalStyles.body_wrapper}>
      <div className={styles.fa_wrapper_outer}>
        <div className={styles.fa_wrapper_inner}>
          <h3>Secondary Validation</h3>
          <div className={styles.fa_input_boxes_row}>
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="first"
              maxLength="1"
              value={token.first}
              ref={inputRef}
              onChange={handleTokenInput}
            />
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="second"
              maxLength="1"
              value={token.second}
              onChange={handleTokenInput}
            />
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="third"
              maxLength="1"
              value={token.third}
              onChange={handleTokenInput}
            />
            <span>-</span>
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="fourth"
              maxLength="1"
              value={token.fourth}
              onChange={handleTokenInput}
            />
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="fifth"
              maxLength="1"
              value={token.fifth}
              onChange={handleTokenInput}
            />
            <input
              className={styles.fa_input_boxes}
              type="text"
              name="sixth"
              maxLength="1"
              value={token.sixth}
              onChange={handleTokenInput}
            />
          </div>
          {errMsg && <p className={styles.fa_errmsg}>{errMsg}</p>}
          <div className={styles.fa_button_row}>
            <button className={styles.fa_button} onClick={handleVerify}>
              Send
            </button>
            <button className={styles.fa_button} onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFA;
