import "../../css/StartPage.css";
import { useState } from "react";
import ListItems from "./ListItems.jsx";
import { useGetListQuery } from "../../app/api/listApiSlice.js";
import { useGetLongTermListQuery } from "../../app/api/longTermApiSlice.js";
import TooSeeList from "./TooSeeList.jsx";
import LongTermList from "../longtermlist/LongTermList.jsx";
import Spinner from "../../components/loaders/Spinner.jsx";
import LongTermResInput from "../longtermlist/LongTermResInput.jsx";
import LongTermViewer from "../longtermlist/LongTermViewer.jsx";
import useAuth from "../../hooks/useAuth.jsx";

export default function ToDoMaker() {
  const { data, isSuccess, isLoading, isError, error } = useGetListQuery();
  const {
    data: ltData,
    isSuccess: ltSuccess,
    isLoading: ltLoading,
    isError: ltIsError,
    error: lterror,
  } = useGetLongTermListQuery("LongTerm", { pollingInterval: 3000 });

  const type = [
    "Admission",
    "Readmission",
    "Significant Change",
    "Quarterly",
    "Annual",
    "PRN",
  ];

  const { location, username } = useAuth();

  const [viewId, setViewID] = useState("");
  const [editId, setEditID] = useState("");
  const [view, setView] = useState(false);
  const [ltView, setLtView] = useState(false);
  const [buttonView, setButtonView] = useState({ save: false, update: false });

  const handleViewToggle = (boolean, id) => {
    setView(boolean);
    setViewID(id);
  };

  const handleEditToggle = (id) => {
    setEditID(id);
    setLtView(true);
    setButtonView((prev) => ({ ...prev, update: true }));
  };

  const handleNewToggle = () => {
    setLtView(true);
    setButtonView((prev) => ({ ...prev, save: true }));
  };

  const handleCloseToggle = () => {
    setLtView(false);
    setButtonView((prev) => ({ ...prev, update: false, save: false }));
  };

  let ltcontent;
  if (ltLoading) ltcontent = <Spinner />;

  if (ltSuccess) {
    ltcontent = (
      <LongTermList
        data={ltData}
        handleViewToggle={handleViewToggle}
        handleEditToggle={handleEditToggle}
        handleNewToggle={handleNewToggle}
      />
    );
  }

  if (ltIsError)
    ltcontent = (
      <div>
        <p>{lterror?.data?.message}</p>
      </div>
    );

  let content;
  if (isLoading) content = <p>Loading... </p>;

  if (isSuccess) {
    const { ids, entities } = data;

    const filteredList = ids.filter(
      (listId) =>
        entities[listId].assessnum > 0 &&
        entities[listId].location === location &&
        entities[listId].user === username
    );

    const assess = ["Quarterly", "PRN", "Annual", "Significant Change"];

    const listItemContent = type.map((type, i) => (
      <div key={i} className="inner-container">
        <div className="inner-inner-container">
          <h1>{type}</h1>
          <table className="inner-container-table">
            <thead>
              <tr>
                <th className="inner-container-title_first_th">
                  <h5 className="inner-container-title">Assess Number</h5>
                </th>
                <th>
                  <h4 className="inner-container-title">Date</h4>
                </th>
                <th>
                  <h4 className="inner-container-title">Name</h4>
                </th>
                {assess.some((a) => a === type) && (
                  <th>
                    <h4 className="inner-container-title">LT Data</h4>
                  </th>
                )}
                <th>
                  <h4 className="inner-container-title">Room #</h4>
                </th>
                <th>
                  <span></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <ListItems type={type} assess={assess} />
            </tbody>
          </table>
        </div>
      </div>
    ));

    content = (
      <div className="list-container-outer">
        <div className="start_page_line">
          <div className="assess_too_see">
            <h3 className="assess_too_see_title">Resident's to see</h3>
            <ul className="inner-container-list-assess">
              {filteredList.map((listId) => (
                <TooSeeList key={listId} listId={listId} />
              ))}
            </ul>
          </div>
          <div className="lt_start_page_column">
            {ltcontent}
            {view && (
              <LongTermViewer
                data={ltData}
                id={viewId}
                handleViewToggle={handleViewToggle}
              />
            )}
          </div>
        </div>
        {ltView && (
          <LongTermResInput
            data={ltData}
            editId={editId}
            ltView={ltView}
            handleCloseToggle={handleCloseToggle}
            buttonView={buttonView}
          />
        )}
        {!ltView && <div className="list-container">{listItemContent}</div>}
      </div>
    );
  }

  if (isError) {
    const listItemContent = type.map((type) => (
      <div key={type} className="inner-container">
        <h1>{type}</h1>
        <ul>
          <li>{error?.data?.message}</li>
        </ul>
      </div>
    ));
    const tooSeeContent = (
      <div className="inner-container-list">
        <h3>Resident's to see</h3>
      </div>
    );

    content = (
      <div className="list-continer-outer">
        <div className="list-container">{listItemContent}</div>
        {tooSeeContent}
      </div>
    );
  }

  return content;
}
