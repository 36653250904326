import styles from "../../../css/SummaryModal.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import parse from "html-react-parser";
import { convert } from "html-to-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import DOMpurify from "dompurify";
import { caaCreator } from "../../../utils/progressNoteHelperFunctions";
import { malnutritionRiskFunction } from "../../../utils/summaryHelperFunctions";
import { bwtProgressTemplate } from "../../../utils/bwtProgressTemplate";
import { wrProgressTemplate } from "../../../utils/wrProgressTemplate";
import useAuth from "../../../hooks/useAuth";

const ProgressModal = ({ data, closeModal }) => {
  const { location } = useAuth();

  let progressNote;

  if (location === "bwt") {
    progressNote = bwtProgressTemplate(data);
  }

  if (location === "wr") {
    progressNote = wrProgressTemplate(data);
  }

  const cleanedProgressNote = DOMpurify.sanitize(progressNote);
  const convertedProgressNote = convert(cleanedProgressNote, {
    selectors: [{ selector: "ul", options: { itemPrefix: " ❖ " } }],
  });
  const parsedProgressNote = parse(cleanedProgressNote);
  const name = data.firstName;
  const malnutRiskScreenName = malnutritionRiskFunction(data).malName;
  const malnutRiskNumber = malnutritionRiskFunction(data);

  const caaCopy = caaCreator(data).raw;
  const malnutritionCopy = name + malnutRiskScreenName.slice(38);

  return (
    <div className={styles.summary_modal_progress_container}>
      <div className={styles.summary_modal_title_row}>
        <h4 className={styles.summary_modal_title}>Progress Note</h4>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Progress Note")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.modal_progress_note} style={{ marginTop: "5px" }}>
        {parsedProgressNote}
      </div>
      <div className={styles.modal_progress_note_footer}>
        <CopyToClipboard text={convertedProgressNote} onCopy={(text) => text}>
          <button className={styles.button}>Copy Progress Note</button>
        </CopyToClipboard>
        {malnutRiskNumber.total.num > 0 && (
          <CopyToClipboard text={malnutritionCopy} onCopy={(text) => text}>
            <button className={styles.button}>Copy Malnutrition Risk</button>
          </CopyToClipboard>
        )}
        {caaCopy && (
          <CopyToClipboard text={caaCopy} onCopy={(text) => text}>
            <button className={styles.button}>Copy CAA information Risk</button>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
};

export default ProgressModal;
