import { useState } from "react";

import { tfs } from "../../../utils/staticData";

import SaveButton from "../../../components/buttons/SaveButton";

import { useUpdateItemMutation } from "../../../app/api/listApiSlice";

//helperFunctions
import {
  freeWaterFunction,
  tfCalculationFunction,
} from "../../../utils/enteralHelperFunctions";

//styles
import styles from "../../../css/EnteralModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { nutrientCalc } from "../../../utils/helperFunctions";

const EnteralModal = ({ data, closeModal }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSaveVisible, setSaveIsVisible] = useState(false);
  const [inputs, setInputs] = useState({
    id: data._id,
    currTFmethod: "bolus",
    recTFmethod: "bolus",
    tfType: data.tfType,
    tfRate: data.tfRate,
    recTFRate: null,
    tfPerDay: data.tfPerDay,
    recTFperDay: null,
    freeFlush: data.freeFlush,
    medFlush: data.medFlush,
    bolusFlush: data.bolusFlush,
    enteralRecs: true,
  });

  const [updateItem, { isError, error }] = useUpdateItemMutation();

  const nutrient = nutrientCalc(data);

  const water = freeWaterFunction(inputs);
  const calc = tfCalculationFunction(inputs, data);
  const total = [
    calc.tfVolume,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const totalvolume = total.reduce((a, b) => a + b, 0);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setInputs((prevState) => ({ ...prevState, [name]: checked }));
  };
  const handleNestedChange = (e) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "freeFlush":
        setInputs({
          ...inputs,
          freeFlush: { ...inputs.freeFlush, [childKey]: value },
        });
        break;
      case "medFlush":
        setInputs({
          ...inputs,
          medFlush: { ...inputs.medFlush, [childKey]: value },
        });
        break;
      case "bolusFlush":
        setInputs({
          ...inputs,
          bolusFlush: { ...inputs.bolusFlush, [childKey]: value },
        });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);

    await updateItem(inputs);

    setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(true);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(false);
    }, 5000);
  };

  return (
    <>
      <div className={styles.enteral_modal_top_close}>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Enteral")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.enteral_modal_container}>
        <div className={styles.enteral_modal_column_one}>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <span>
              Current wt:{" "}
              {data.wt
                ? `${Math.round(data.wt / 2.2)} kg`
                : "No Weight Inputed Currently"}
            </span>
          </div>
          <div>
            <span>
              Calorie Needs:{" "}
              {nutrient.nutrientCalories
                ? nutrient.nutrientCalories
                : "No Calorie Needs Inputed"}
            </span>
          </div>
          <div>
            <span>
              Protein Needs:{" "}
              {nutrient.nutrientProtein
                ? nutrient.nutrientProtein
                : "No Protein Needs Inputed"}
            </span>
          </div>
          <div>
            <span>
              Fluid Needs:{" "}
              {nutrient.nutrientFluids
                ? nutrient.nutrientFluids
                : "No Protein Needs Inputed"}
            </span>
          </div>
          <div>
            <span>Tube Feeding Method: </span>
            <select name="currTFmethod" onChange={handleChange}>
              <option value="bolus">Bolus</option>
              <option value="continuous">Continuous</option>
              <option value="cyclic">Cyclic</option>
            </select>
          </div>
          <div>
            {inputs.currTFmethod === "bolus" ? (
              <>
                <span>Tube Feeding mL per Bolus: </span>
                <input
                  type="text"
                  name="tfRate"
                  value={inputs.tfRate}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <span>Tube Feeding Rate (mL/hr): </span>
                <input
                  type="text"
                  name="tfRate"
                  style={{ width: "30px" }}
                  value={inputs.tfRate}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          <div>
            {inputs.currTFmethod === "bolus" ? (
              <>
                <span>How Many Boluses Per Day: </span>
                <input
                  type="text"
                  name="tfPerDay"
                  value={inputs.tfPerDay}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <span>Hrs/Times per day: </span>
                <input
                  type="text"
                  name="tfPerDay"
                  value={inputs.tfPerDay}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {calc.tfVolume > 0 && (
            <div>
              <span style={{ fontSize: "13px" }}>
                Total TF Volume {calc.tfVolume} mL/day
              </span>
            </div>
          )}
          {calc.tfVolume > 0 && (
            <div>
              <span style={{ fontSize: "13px" }}>
                Total Volume (TF & Flush) {totalvolume} mL/day
              </span>
            </div>
          )}
          <div className={styles.enteral_modal_flush_columns}>
            <h3>Free Water Flush</h3>
            <div className={styles.enteral_modal_flush_row}>
              <span>Number of Free H20 Flushes </span>
              <input
                type="text"
                name="freeFlush.freeTimes"
                value={inputs.freeFlush.freeTimes}
                onChange={handleNestedChange}
              />
            </div>
            <div className={styles.enteral_modal_flush_row}>
              <span>Amount per Flush(mL) </span>
              <input
                type="text"
                name="freeFlush.freeRate"
                value={inputs.freeFlush.freeRate}
                onChange={handleNestedChange}
              />
            </div>
            {water.freeWaterFlush > 0 && (
              <div>
                <span style={{ fontSize: "13px" }}>
                  {water.freeWaterFlush} mL/day
                </span>
              </div>
            )}
          </div>
          <div className={styles.enteral_modal_flush_columns}>
            <h3>Medication Flushes</h3>
            <div className={styles.enteral_modal_flush_row}>
              <span>Number of Medications </span>
              <input
                type="text"
                name="medFlush.medTimes"
                value={inputs.medFlush.medTimes}
                onChange={handleNestedChange}
              />
            </div>
            <div className={styles.enteral_modal_flush_row}>
              <span>Flushes Before and After Medications (mL) </span>
              <input
                type="text"
                name="medFlush.medRate"
                value={inputs.medFlush.medRate}
                onChange={handleNestedChange}
              />
            </div>
            {water.medWaterFlush > 0 && (
              <div>
                <span style={{ fontSize: "13px" }}>
                  {water.medWaterFlush} mL/day
                </span>
              </div>
            )}
          </div>
          {inputs.currTFmethod === "bolus" && (
            <div className={styles.enteral_modal_flush_columns}>
              <h3>Bolus Flushes</h3>
              <div className={styles.enteral_modal_flush_row}>
                <span>Number of Boluses </span>
                <input
                  type="text"
                  name="bolusFlush.bolusTimes"
                  value={inputs.bolusFlush.bolusTimes}
                  onChange={handleNestedChange}
                />
              </div>
              <div className={styles.enteral_modal_flush_row}>
                <span>Flushes Before and After Bolus </span>
                <input
                  type="text"
                  name="bolusFlush.bolusRate"
                  value={inputs.bolusFlush.bolusRate}
                  onChange={handleNestedChange}
                />
              </div>
              {water.bolusWaterFlush > 0 && (
                <div>
                  <span style={{ fontSize: "13px" }}>
                    {water.bolusWaterFlush} mL/day
                  </span>
                </div>
              )}
            </div>
          )}
          <div>
            <span>Tube Feeeding Type: </span>
            <select name="tfType" value={inputs.tfType} onChange={handleChange}>
              <option value=""></option>
              {tfs.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <span>Total Calories: {`${calc.calories} kcal/volume`}</span>
            <span>
              (
              {calc.tfPercentCalc > 0
                ? Math.round(calc.tfPercentCalc)
                : "Within Range"}
              )
            </span>
          </div>
          <div>
            <span>
              Total Protein:{" "}
              {`${calc.protein && calc.protein.toFixed(1)} gm/volume`}
            </span>
            <span>
              (
              {calc.tfPercentPro > 0
                ? `${Math.round(calc.tfPercentPro)}% of Protein Needs`
                : "Within Range"}
              )
            </span>
          </div>
          <div>
            <span>
              Total Tube Feeding Fluid:{" "}
              {`${Math.round(calc.fluid)} fluid (mL)/volume`}
            </span>
          </div>
          <div>
            <span>
              Total Fluid Volume:{" "}
              {`${Math.round(calc.totalFluidVolume)} fluid (mL)/volume`}
            </span>
            <span>
              (
              {calc.tfPercentFluid > 0
                ? `${Math.round(calc.tfPercentFluid)}% of Fluid Needs`
                : "Within Range"}
              )
            </span>
          </div>
          <div>
            <span>Recommendations ?</span>
            <input
              type="checkbox"
              name="enteralRecs"
              checked={inputs.enteralRecs}
              onChange={handleChecked}
            />
          </div>
          <SaveButton
            title="Save"
            isVisible={isVisible}
            isSaveVisible={isSaveVisible}
            isError={isError}
            error={error}
            event={handleSubmit}
          />
        </div>
        {inputs.enteralRecs && (
          <div className={styles.enteral_modal_column_two}>
            <h4>RECOMMENDATION</h4>
            <div>
              <span>Tube Feeding Method: </span>
              <select name="recTFmethod" onChange={handleChange}>
                <option value="bolus">Bolus</option>
                <option value="continuous">Continuous</option>
                <option value="cyclic">Cyclic</option>
              </select>
            </div>
            <div>
              {inputs.recTFmethod === "bolus" ? (
                <>
                  <span>Tube Feeding mL per Bolus: </span>
                  <input
                    type="number"
                    name="recTfRate"
                    value={inputs.recTfRate}
                    style={{ width: "30px", textAlign: "center" }}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <>
                  <span>Tube Feeding Rate (mL/hr): </span>
                  <input
                    type="number"
                    name="recTfRate"
                    style={{ width: "30px", textAlign: "center" }}
                    value={inputs.recTfRate}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
            <div>
              {inputs.recTFmethod === "bolus" ? (
                <>
                  <span>How Many Boluses Per Day: </span>
                  <input
                    type="number"
                    name="recTFperDay"
                    value={inputs.recTFperDay}
                    style={{ width: "30px", textAlign: "center" }}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <>
                  <span>Hrs/Times per day: </span>
                  <input
                    type="number"
                    name="recTFperDay"
                    value={inputs.recTFperDay}
                    style={{ width: "30px", textAlign: "center" }}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
            {calc.recTFvolume > 0 && (
              <div>
                <span style={{ fontSize: "13px" }}>
                  Total TF Volume {calc.recTFvolume} mL/day
                </span>
              </div>
            )}
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Formula Name</th>
                  <th>Calories</th>
                  <th>Protein</th>
                  <th>Water</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tfs.map((tf) => (
                  <tr>
                    <td>{tf.name}</td>
                    <td>
                      {calc.recTFvolume > 0 && tf.calories * calc.recTFvolume}
                    </td>
                    <td>
                      {calc.recTFvolume > 0 &&
                        Math.round(tf.protein * calc.recTFvolume)}
                    </td>
                    <td>
                      {calc.recTFvolume > 0 &&
                        Math.round(tf.fluid * calc.recTFvolume)}
                    </td>
                    <td>{calc.recTFvolume > 0 && <input type="checkbox" />}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default EnteralModal;
