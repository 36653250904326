import styles from "../../../css/DataEntry.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { NESTED_IDS, problemList } from "../../../utils/staticData.js";

export default function NutritionDiagnosis({
  item,
  handleNestedchange,
  handleRemove,
}) {
  return (
    <>
      <div className={styles.data_entry_nutrition_diagnosis_title_block}>
        <h5 className={styles.data_entry_nutrition_diagnosis_title}>
          Diagnosis #{item.num}
        </h5>
        {item.num >= 2 && (
          <span>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) =>
                handleRemove(NESTED_IDS.nutritionDiagnosis, item.id)
              }
              style={{
                color: "#6e6d70",
                cursor: "pointer",
                paddingLeft: "6px",
              }}
            />
          </span>
        )}
      </div>
      <div className={styles.data_entry_nutrition_diagnosis}>
        <span>Problem</span>
        <select
          name="nutritionDiagnosis.problem"
          value={item.problem}
          onChange={(e) => handleNestedchange(e, item.id)}>
          {problemList.map((item) => (
            <option value={item} key={item.toLowerCase()}>
              {item}
            </option>
          ))}
        </select>

        <span>Etiology</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.etiology"
          value={item.etology}
          defaultValue={item.etiology}
          onChange={(e) => handleNestedchange(e, item.id)}></textarea>

        <span>Symptoms</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.symptom"
          value={item.symtpom}
          defaultValue={item.symptom}
          onChange={(e) => handleNestedchange(e, item.id)}></textarea>
      </div>
    </>
  );
}
