import { selectUBW } from "../../../utils/helperFunctions";

const UBW = ({ inputs }) => {
  const ubw = selectUBW(inputs.wt, inputs.ubwLow, inputs.ubwHigh);

  return (
    <div>
      {ubw !== undefined && ubw !== 0.0 ? (
        <span>{`${ubw?.toFixed(1)}% `}</span>
      ) : (
        <span></span>
      )}
      {ubw === 0.0 && (
        <span>Current weight is within usual body weight range</span>
      )}
      {ubw < 0.0 && <span> below usual body weight range</span>}
      {ubw > 0.0 && <span> above usual body weight range</span>}
    </div>
  );
};

export default UBW;
