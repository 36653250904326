import { setIBW } from "../../../utils/helperFunctions";

const IBW = ({ inputs, handleChange, data }) => {
  const ibw = setIBW(inputs.ht, inputs.sex);

  const ibwChecker = ibw === 0 ? "No height at this time" : ibw;
  const percentIBW = (inputs.wt / ibw) * 100;

  console.log(typeof inputs.wt);

  return (
    <div>
      <span>IBW:</span>
      <input
        name="ibw"
        style={ibw !== 0 ? { width: "1.6rem" } : { width: "9rem" }}
        value={ibwChecker}
        readOnly={true}
        disabled={true}
        onChange={handleChange}
      />
      {inputs.ht > 0 && <span style={{ marginLeft: "0.1rem" }}>Lbs</span>}
      {inputs.ht && <span style={{ marginLeft: "10px" }}>Percent IBW</span>}
      {inputs.ht && (
        <span style={{ marginLeft: "5px" }}>{Math.round(percentIBW)} %</span>
      )}
    </div>
  );
};

export default IBW;
