import styles from "../../../css/AssessmentForm.module.css";

import React from "react";

const AppetiteStim = ({ item, handleNestedChange }) => {
  return (
    <div className={styles.lower_form_appetite_list}>
      <input
        type="checkbox"
        name="appetiteStim.checked"
        checked={item.checked}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span>{item.name}</span>
    </div>
  );
};
export default AppetiteStim;
