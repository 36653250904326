import { useGetListQuery } from "../../app/api/listApiSlice";
import useAuth from "../../hooks/useAuth";
import AssessmentSeeList from "../../features/assessments/AssessmentSeeList";
import styles from "../../css/Assessment.module.css";
const NavForm = () => {
  const { data, isLoading, isSuccess, isError, error } = useGetListQuery();
  const { location, username } = useAuth();
  let content;
  if (isLoading) {
    content = <p>Loading... </p>;
  }

  if (isSuccess) {
    const { ids, entities } = data;

    const filteredList = ids.filter(
      (listId) =>
        entities[listId].assessnum > 0 && entities[listId].location === location
    );

    content = (
      <ul className={styles.inner_container_list_ul}>
        {filteredList.map((listId) => (
          <AssessmentSeeList key={listId} listId={listId} />
        ))}
      </ul>
    );
  }

  if (isError) {
    content = <p>{error?.data?.message}</p>;
  }

  return (
    <>
      <div className={styles.assess_too_see_container}>
        <h3>Resident's to see</h3>
        {content}
      </div>
    </>
  );
};

export default NavForm;
