import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../utils/staticData";
import styles from "../../../index.module.css";

const InputFields = ({ item, handleNestedChange, handleRemove }) => {
  return (
    <>
      <input
        type="text"
        name="inputFields.value"
        value={item.value}
        onChange={(e) => handleNestedChange(e, item.id)}
      />
      <span className={styles.list_addition_button}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.inputfields, item.id)}
          style={{
            color: "#6e6d70",
            cursor: "pointer",
          }}
        />
      </span>
    </>
  );
};

export default InputFields;
