import { useEffect, useState, useRef } from "react";
import { useAddNewListItemMutation } from "../../app/api/listApiSlice";
import { BWT_ROOMS, WR_ROOMS } from "../../utils/staticData";
import useAuth from "../../hooks/useAuth";
import "../../css/StartPage.css";

export default function Lister({ users }) {
  const [addNewItem, { isLoading, isSuccess, isError, error }] =
    useAddNewListItemMutation();

  const { username, location } = useAuth();

  const [admitDate, setAdmitDate] = useState("");
  const [ageDate, setAgeDate] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [assessType, setAssessType] = useState("");
  const [room, setRoom] = useState("");

  const admitRef = useRef();
  const ageRef = useRef();

  let roomList;

  if (location === "bwt") {
    roomList = BWT_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  if (location === "wr") {
    roomList = WR_ROOMS.map((room, i) => (
      <option key={i} value={room}>
        {room}
      </option>
    ));
  }

  useEffect(() => {
    if (isSuccess) {
      setAdmitDate("");
      setAgeDate("");
      setLastName("");
      setFirstName("");
      setAssessType("");
      setRoom("");
      admitRef.current.type = "text";
      ageRef.current.type = "text";
    }
  }, [isSuccess]);
  const onLastNameChanged = (e) => setLastName(e.target.value);
  const onFirstNameChanged = (e) => setFirstName(e.target.value);
  const onTypeChanged = (e) => setAssessType(e.target.value);
  const onRoomChanged = (e) => setRoom(e.target.value);

  const onAdmitDateChanged = (e) => {
    setAdmitDate(e.target.value);
  };

  const onAgeDateChanged = (e) => {
    setAgeDate(e.target.value);
  };

  const canSave =
    [admitDate, ageDate, lastName, firstName, assessType, room].every(
      Boolean
    ) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewItem({
        date: admitDate,
        age: ageDate,
        lastName,
        firstName,
        assessType,
        room,
        assessnum: null,
        user: username,
        location,
      });
    }
  };

  const content = (
    <div className="name-submitter">
      <form onSubmit={handleSubmit}>
        <input
          className="input"
          type="text"
          name="date"
          placeholder="Admit Date"
          onFocus={(e) => (e.target.type = "date")}
          onChange={onAdmitDateChanged}
          ref={admitRef}
          pattern=""
          style={{ width: "7.5rem" }}
          value={admitDate}
        />
        <input
          className="input"
          type="text"
          name="date"
          placeholder="Date of Birth"
          onFocus={(e) => (e.target.type = "date")}
          onChange={onAgeDateChanged}
          ref={ageRef}
          pattern=""
          style={{ width: "7.5rem" }}
          value={ageDate}
        />
        <input
          type="text"
          name="lastName"
          className="input"
          placeholder="Last Name"
          onChange={onLastNameChanged}
          value={lastName}
        />
        <input
          type="text"
          name="firstName"
          className="input"
          placeholder="First Name"
          onChange={onFirstNameChanged}
          value={firstName}
        />
        <select className="input" value={room} onChange={onRoomChanged}>
          <option value="">Room #</option>
          {roomList}
        </select>
        <select
          name="type"
          className="input"
          id=""
          onChange={onTypeChanged}
          value={assessType}>
          <option value="">Assess Type</option>
          <option value="Admission">Admission</option>
          <option value="Readmission">Readmission</option>
          <option value="Significant Change">Significant Change</option>
          <option value="Annual">Annual</option>
          <option value="Quarterly">Quarterly</option>
          <option value="PRN">PRN</option>
        </select>

        <button className="button">Add</button>
      </form>
      {isError && <div>{error.message}</div>}
    </div>
  );

  return content;
}
