import { useState, useEffect } from "react";

//named functions
import { ageCalc } from "../../../utils/helperFunctions.js";
import {
  NESTED_IDS,
  hardCodeArrays as setterArrays,
} from "../../../utils/staticData.js";

//components
import SupplementList from "./SupplementList.jsx";
import FoodAllergies from "./FoodAllergies";
import FoodIntolerance from "./FoodIntolerance";

//styles
import styles from "../../../css/AssessmentForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

const UpperAssess = ({
  ard,
  inputs,
  handleChange,
  handleNestedChange,
  handleRemove,
  handleAddition,
  handleEnteralToggle,
}) => {
  useEffect(() => {
    if (
      inputs.assessType === "Admission" ||
      inputs.assessType === "Readmission" ||
      inputs.assessType === "Significant Change"
    ) {
      setToggle((prev) => ({ ...prev, checkType: true }));
    } else {
      setToggle((prev) => ({ ...prev, checkType: false }));
    }
  }, [inputs.assessType]);

  const mappedSups = inputs.supplementList
    .map((item) => item.supname)
    .filter((elm) => elm);
  const mappedAllergy = inputs.foodAllergy
    .map((item) => item.allergyname)
    .filter((elm) => elm);
  const mappedIntollerance = inputs.foodIntolerance
    .map((item) => item.intolerancename)
    .filter((elm) => elm);

  useEffect(() => {
    if (mappedSups.length > 0)
      setToggle((prev) => ({ ...prev, supplement: true }));
    if (mappedAllergy.length > 0)
      setToggle((prev) => ({ ...prev, allergy: true }));
    if (mappedIntollerance.length > 0)
      setToggle((prev) => ({ ...prev, intolerance: true }));
    if (inputs.ivfluids) setToggle((prev) => ({ ...prev, ivfluids: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleEnteralToggle(inputs.enteralBoolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.enteralBoolean]);

  const [toggle, setToggle] = useState({
    checkType: false,
    supplement: false,
    allergy: false,
    intolerance: false,
    ivfluids: false,
  });

  const handleEnteralChange = (e) => {
    handleChange(e);
  };

  return (
    <>
      <h4 className={styles.section_title}>
        Assessment Type \\ Diet Type \\ Dentition
      </h4>
      <div className={styles.upper_assess_info_line}>
        <div>
          <span style={{ marginLeft: "0.25rem" }}>ARD: </span>
          <span>
            {new Date(ard).toLocaleDateString("en-US", { timeZone: "UTC" })}
          </span>
        </div>
        {inputs.ltsave && (
          <div>
            <span style={{ color: "red", marginLeft: "15px" }}>
              Long Term Saved
            </span>
          </div>
        )}
      </div>
      <div>
        <select
          name="clientType"
          value={inputs.clientType}
          onChange={handleChange}>
          <option value="Resident">Resident</option>
          <option value="Pt">Patient</option>
        </select>
        <input
          type="text"
          style={{ width: "10rem" }}
          name="lastName"
          value={inputs.lastName}
          placeholder="Last Name"
          onChange={handleChange}
        />
        <input
          type="text"
          style={{ width: "10rem" }}
          name="firstName"
          value={inputs.firstName}
          placeholder="First Name"
          onChange={handleChange}
        />
        <input
          type="text"
          style={{ width: "5rem" }}
          name="room"
          value={inputs.room}
          placeholder="Room #"
          onChange={handleChange}
        />
        <span style={{ marginLeft: "0.5rem" }}>Sex:</span>
        <select name="sex" value={inputs.sex} onChange={handleChange}>
          <option value=""></option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <label>
        Current Assessment:
        <select
          name="assessType"
          value={inputs.assessType}
          onChange={handleChange}
          style={{ marginRight: "1rem" }}>
          <option value="Admission">Admission</option>
          <option value="Readmission">Readmission</option>
          <option value="Significant Change">Significant Change</option>
          <option value="Annual">Annual</option>
          <option value="Quarterly">Quarterly</option>
          <option value="PRN">PRN</option>
        </select>
      </label>
      <span style={{ marginLeft: "0.25rem" }}>Age: </span>
      <input
        type="date"
        name="age"
        placeholder="DOB"
        style={{ width: "7rem", marginRight: "5px" }}
        value={inputs.age}
        onChange={handleChange}
      />
      {inputs.age && `(${ageCalc(inputs.age)} years old )`}
      {toggle.checkType && (
        <>
          <div className={styles.assess_form_admit}>
            <label>
              Admit Dx/Reason:
              <input
                type="text"
                name="admitType"
                value={inputs.admitType}
                onChange={handleChange}
                style={{ width: "22rem" }}
              />
            </label>
            <label>
              Admit From:
              <input
                type="text"
                name="admitLocal"
                value={inputs.admitLocal}
                onChange={handleChange}
                style={{ width: "18rem" }}
              />
            </label>
          </div>
          <div>
            <label>
              Diet Prior to Admission:
              <input
                type="text"
                name="dietPrior"
                value={inputs.dietPrior}
                onChange={handleChange}
              />
            </label>
          </div>
        </>
      )}
      {!toggle.checkType && (
        <div className={styles.upper_assess_previous_assessment}>
          <label>
            Previous Assessment:
            <select
              name="prevAssessType"
              value={inputs.prevAssessType}
              onChange={handleChange}>
              <option></option>
              <option value="Admission">Admission</option>
              <option value="Readmission">Readmission</option>
              <option value="Significant Change">Significant Change</option>
              <option value="Annual">Annual</option>
              <option value="Quarterly">Quarterly</option>
              <option value="PRN">PRN</option>
            </select>
          </label>
          <label>
            Date Completed:
            <input
              type="date"
              name="prevDate"
              value={inputs.prevDate}
              onChange={handleChange}
            />
          </label>
        </div>
      )}
      <div>
        <label>
          Diet:
          <input
            type="text"
            list="therapeutic diet"
            placeholder="Diet Type"
            name="theraDiet"
            value={inputs.theraDiet}
            onChange={handleChange}
          />
          <datalist id="therapeutic diet">
            {setterArrays.theraDiets.map((t, i) => (
              <option key={i} value={t} />
            ))}
          </datalist>
          <input
            type="text"
            list="texture mod"
            placeholder="Texture of Foods"
            name="texture"
            value={inputs.texture}
            onChange={handleChange}
          />
          <datalist id="texture mod">
            {setterArrays.textMod.map((t, i) => (
              <option key={i} value={t} />
            ))}
          </datalist>
          <input
            type="text"
            list="liquid mod"
            placeholder="Texture of Liquids"
            name="liquids"
            value={inputs.liquids}
            onChange={handleChange}
          />
          <datalist id="liquid mod">
            {setterArrays.liquidMod.map((t, i) => (
              <option key={i} value={t} />
            ))}
          </datalist>
          <input
            type="date"
            name="dietstartdate"
            value={inputs.dietstartdate}
            onChange={handleChange}
          />
        </label>
        {new Date(inputs.dietstartdate) > new Date(inputs.prevDate) && (
          <span style={{ color: "red" }}>Changed</span>
        )}
      </div>
      <div>
        <span>Enteral Nutrition? </span>
        <input
          type="checkbox"
          name="enteralBoolean"
          checked={inputs.enteralBoolean}
          onChange={handleEnteralChange}
        />
        <span>Yes/No</span>
      </div>
      <div>
        <div>
          <span>Is the Patient/Resident on a Supplement?</span>
          <span>Yes/No:</span>
          <input
            type="checkbox"
            checked={toggle.supplement}
            onChange={(e) =>
              setToggle((prev) => ({
                ...prev,
                supplement: e.target.checked,
              }))
            }
          />
        </div>
        {toggle.supplement && (
          <div>
            <div>
              <span>Supplements</span>
              <span className={styles.list_addition_button}>
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  size="lg"
                  id="supplementList"
                  onClick={(e) => handleAddition(NESTED_IDS.sup)}
                  style={{
                    color: "#6e6d70",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            {inputs.supplementList.map((sup) => (
              <SupplementList
                key={sup.id}
                sup={sup}
                prevDate={inputs.prevDate}
                handleSupChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
          </div>
        )}
      </div>
      <div>
        <label>
          {" "}
          Food Intake:
          <select
            name="foodIntake"
            value={inputs.foodIntake}
            onChange={handleChange}>
            {setterArrays.intakeamt.map((f, i) => (
              <option key={i} value={f}>
                {f}
              </option>
            ))}
          </select>
        </label>
        <span style={{ marginLeft: "1rem" }}>Supplement intake:</span>
        <select
          name="supIntake"
          value={inputs.supIntake}
          onChange={handleChange}>
          {setterArrays.intakeamt.map((f, i) => (
            <option key={i} value={f}>
              {f}
            </option>
          ))}
        </select>
        <span style={{ marginLeft: "1rem" }}>Eating (Self Performance):</span>
        <select
          name="eatSelfPerform"
          value={inputs.eatSelfPerform}
          onChange={handleChange}>
          {setterArrays.eatSelfPerformance.map((e, i) => (
            <option key={i} value={e}>
              {e}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>
          {" "}
          Eating (Support Provided):
          <select
            name="eatSupportPerform"
            value={inputs.eatSupportPerform}
            onChange={handleChange}>
            {setterArrays.eatSupportPerform.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </label>
        <span style={{ marginLeft: "1rem" }}>Portion Size:</span>
        <select
          name="portionSize"
          value={inputs.portionSize}
          onChange={handleChange}>
          {setterArrays.portionSize.map((p, i) => (
            <option key={i} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.allergy_list_question}>
        <span>
          Does the Patient/Resident have <strong>Food Allergies</strong>?
        </span>
        <label>
          Yes/No:
          <input
            name="foodAllergy"
            checked={toggle.allergy}
            type="checkbox"
            onChange={(e) =>
              setToggle((prev) => ({ ...prev, allergy: e.target.checked }))
            }
          />
        </label>
        <span>
          <strong>Food Intolerances</strong>?
        </span>
        <label>
          Yes/No:
          <input
            name="foodIntolerance"
            type="checkbox"
            checked={toggle.intolerance}
            onChange={(e) =>
              setToggle((prev) => ({
                ...prev,
                intolerance: e.target.checked,
              }))
            }
          />
        </label>
      </div>
      {toggle.allergy && (
        <div className={styles.allergy_list}>
          <span>Food Allergies:</span>
          {inputs.foodAllergy.map((allergy) => (
            <FoodAllergies
              key={allergy.id}
              allergy={allergy}
              handleNestedChange={handleNestedChange}
              handleRemove={handleRemove}
            />
          ))}
          <span>
            <FontAwesomeIcon
              icon={faSquarePlus}
              size="lg"
              id="supplementList"
              onClick={(e) => handleAddition(NESTED_IDS.allergy)}
              style={{
                color: "#6e6d70",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      )}
      {toggle.intolerance && (
        <div className={styles.intolerance_list}>
          <span>Food Intolerances:</span>
          {inputs.foodIntolerance.map((intol) => (
            <FoodIntolerance
              key={intol.id}
              intol={intol}
              handleNestedChange={handleNestedChange}
              handleRemove={handleRemove}
            />
          ))}
          <span className={styles.list_addition_button}>
            <FontAwesomeIcon
              icon={faSquarePlus}
              size="lg"
              id="supplementList"
              onClick={(e) => handleAddition(NESTED_IDS.intolerance)}
              style={{
                color: "#6e6d70",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      )}
      <div>
        <label>
          {" "}
          Dentition:
          <input
            type="text"
            list="upperdent"
            name="upperDent"
            value={inputs.upperDent}
            placeholder="Upper Dentition"
            onChange={handleChange}
          />
          <datalist id="upperdent">
            {setterArrays.upperdent.map((d, i) => (
              <option key={i} value={d} />
            ))}
          </datalist>
          <input
            type="text"
            list="lowerdent"
            name="lowerDent"
            value={inputs.lowerDent}
            placeholder="Lower Dentition"
            onChange={handleChange}
          />
          <datalist id="lowerdent">
            {setterArrays.lowerdent.map((d, i) => (
              <option key={i} value={d} />
            ))}
          </datalist>
        </label>
      </div>
      <div className={styles.adapt_equip_line}>
        <span className={styles.adapt_equip_line_a}>Adaptive Equipment:</span>
        <textarea
          style={{ width: "20rem", height: "3rem" }}
          name="adaptEquip"
          value={inputs.adaptEquip}
          onChange={handleChange}></textarea>

        <span className={styles.adapt_equip_item_c}>Ambulation:</span>
        <select
          name="ambulation"
          className={styles.adapt_equip_item_d}
          value={inputs.ambulation}
          onChange={handleChange}>
          <option value=""></option>
          <option value="ambulatory">Ambulatory</option>
          <option value="walker/cane/crutch">Walker/cane/crutch</option>
          <option value="wheelchair">Wheelchair</option>
          <option value="paces/wanders">Paces/Wanders</option>
          <option value="chair/bedfast">Chair/Bedfast</option>
          <option value="scooter">Scooter</option>
        </select>
      </div>
      {inputs.assessType === "Admission" ||
      inputs.assessType === "Readmission" ||
      inputs.assessType === "Significant Change" ? (
        <div className={styles.upper_assess_iv_fluids}>
          <span style={{ marginRight: "10px" }}>
            IV Fluids Prior to Admission ?{" "}
          </span>
          <span style={{ marginRight: "3px" }}>Yes/No:</span>
          <input
            type="checkbox"
            checked={toggle.ivfluids}
            onChange={(e) =>
              setToggle((prev) => ({
                ...prev,
                ivfluids: e.target.checked,
              }))
            }
          />
          {toggle.ivfluids && (
            <>
              <span style={{ marginRight: "5px", marginLeft: "1rem" }}>
                Order:
              </span>
              <input
                name="ivfluids"
                type="text"
                value={inputs.ivfluids}
                onChange={handleChange}
                style={{ width: "24.75rem" }}
              />
            </>
          )}
        </div>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default UpperAssess;
