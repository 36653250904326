import { useSelector } from "react-redux";
import {
  selectAllLists,
  useDeleteItemMutation,
  useUpdateItemMutation,
} from "../../app/api/listApiSlice";
import { useState } from "react";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAuth from "../../hooks/useAuth";

export default function ListItems({ type, assess }) {
  const list = useSelector((state) => selectAllLists(state));

  const { username, location } = useAuth();

  const [assessNum, setAssessNum] = useState("");

  const [updateItem] = useUpdateItemMutation();

  const [deleteItem] = useDeleteItemMutation();

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateItem({ id: e.target.value, assessnum: Number(assessNum) });
    setAssessNum("");
  };

  const onDeleteItemClicked = async (id) => {
    await deleteItem({ id });
  };

  return list
    .filter(
      (p) =>
        p.assessType === type && p.user === username && p.location === location
    )
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((res, i) => (
      <tr key={i} className="inner_container_list_row">
        <td className="inner_container_first_td">
          <select
            className="inner_container_list_select"
            onChange={(e) => setAssessNum(e.target.value)}>
            <option value=""></option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
          <button
            className="innter_container_button"
            value={res.id}
            onClick={handleUpdate}>
            Add
          </button>
        </td>
        <td>
          <span>
            (
            {new Date(res.date).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })}
            )
          </span>
        </td>
        <td>
          <span>
            {res.lastName}, {res.firstName}{" "}
          </span>
        </td>
        {res.ltentry && assess.some((a) => a === res.assessType) && (
          <td>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#6e6d70" }} />
          </td>
        )}
        <td>
          <span>{res.room}</span>
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => onDeleteItemClicked(res.id)}
            style={{
              color: "#6e6d70",
              cursor: "pointer",
            }}
          />
        </td>
      </tr>
    ));
}
