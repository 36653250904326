import { tfs } from "./staticData";

import { nutrientCalc } from "./helperFunctions";

export const freeWaterFunction = (inputs) => {
  const freeWaterFlush =
    Number(inputs.freeFlush.freeRate) * Number(inputs.freeFlush.freeTimes);
  const medWaterFlush =
    Number(inputs.medFlush.medRate) * Number(inputs.medFlush.medTimes);
  const bolusWaterFlush =
    Number(inputs.bolusFlush.bolusRate) * Number(inputs.bolusFlush.bolusTimes);

  return { freeWaterFlush, medWaterFlush, bolusWaterFlush };
};

export const tfCalculationFunction = (inputs, data) => {
  const foundTF = tfs.find((item) => item.name === inputs.tfType);
  const tfVolume = Number(inputs.tfRate) * Number(inputs.tfPerDay);
  const recTFvolume = inputs.recTfRate * inputs.recTFperDay;

  const nutrient = nutrientCalc(data);

  const water = freeWaterFunction(inputs);

  const calories = foundTF ? foundTF.calories * tfVolume : "";
  const protein = foundTF ? foundTF.protein * tfVolume : "";
  const fluid = foundTF ? foundTF.fluid * tfVolume : "";
  const calneedslow = Number(nutrient.calLow);
  const calneedshigh = Number(nutrient.calHigh);
  const proneedslow = Number(nutrient.proLow);
  const proneedshigh = Number(nutrient.proHigh);
  const fluidneedslow = Number(nutrient.fluidLow);
  const fluidneedshigh = Number(nutrient.fluidHigh);

  const total = [
    fluid,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const totalFluidVolume = total.reduce((a, b) => a + b, 0);

  let tfPercentCalc;
  let tfPercentPro;
  let tfPercentFluid;
  if (calories < calneedslow) {
    tfPercentCalc = calories / calneedslow;
  } else if (calories > calneedshigh) {
    tfPercentCalc = calories / calneedshigh;
  } else if (
    calneedslow &&
    calories >= calneedslow &&
    calories <= calneedshigh
  ) {
    tfPercentCalc = 0;
  } else {
    tfPercentCalc = "";
  }

  if (protein < proneedslow) {
    tfPercentPro = (protein / proneedslow) * 100;
  } else if (protein > proneedshigh) {
    tfPercentPro = (protein / proneedshigh) * 100;
  } else if (proneedslow && protein >= proneedslow && protein <= proneedshigh) {
    tfPercentPro = 0;
  } else {
    tfPercentPro = "";
  }

  if (totalFluidVolume < fluidneedslow) {
    tfPercentFluid = (totalFluidVolume / fluidneedslow) * 100;
  } else if (totalFluidVolume > fluidneedshigh) {
    tfPercentFluid = (totalFluidVolume / fluidneedshigh) * 100;
  } else if (
    fluidneedslow &&
    totalFluidVolume >= fluidneedslow &&
    totalFluidVolume <= fluidneedshigh
  ) {
    tfPercentFluid = 0;
  } else {
    tfPercentFluid = "";
  }

  return {
    tfVolume,
    recTFvolume,
    calories,
    protein,
    fluid,
    totalFluidVolume,
    tfPercentCalc,
    tfPercentPro,
    tfPercentFluid,
  };
};
