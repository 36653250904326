import styles from "../../../css/AssessmentForm.module.css";

import MedInputs from "./MedInputs";
import VitMinList from "./VitMinList";
import MicronutrientInputs from "./MicronutrientInputs";
import AppetiteStim from "./AppetiteStim.jsx";

import StandardButton from "../../../components/buttons/StandardButton.js";

export default function LowerAssess({
  inputs,
  handleChange,
  handleNestedChange,
  handleRemove,
  handleAddition,
}) {
  return (
    <>
      <h4 className={styles.section_title}>
        PMHx \\ Meds \\ Skin \\ Food Prefs
      </h4>
      <div className={styles.lower_form_outer_container}>
        <div className={styles.lower_form_inner_container}>
          <div className={styles.lower_form_pmh_inputs}>
            <MedInputs
              title="PMHx:"
              name="pmhx"
              value={inputs.pmhx}
              handleChange={handleChange}
            />
            <MedInputs
              title="Medications:"
              name="meds"
              value={inputs.meds}
              handleChange={handleChange}
            />
            <MedInputs
              title="Skin:"
              name="skin"
              value={inputs.skin}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.lower_form_vitmin_container}>
          <h4 className={styles.lower_form_subtitle}>Vitamins/Minerals:</h4>
          <div className={styles.lower_form_vitmin_flex}>
            {inputs.micronutrients.map((m) => (
              <VitMinList
                key={m.id}
                micro={m}
                handleNestedChange={handleNestedChange}
              />
            ))}
            <h4 className={styles.lower_form_subtitle}>Other</h4>
            {inputs.micronutrientInputs.map((item) => (
              <MicronutrientInputs
                item={item}
                key={item.id}
                handleNestedChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
            <StandardButton
              event={handleAddition}
              name="micronutrientInputs"
              title="Add New Input"
              style={{ paddingTop: "6px" }}
            />
            <h4 className={styles.lower_form_subtitle}>Appetite Stimulant:</h4>
            {inputs.appetiteStim.map((item) => (
              <AppetiteStim
                key={item.id}
                item={item}
                handleNestedChange={handleNestedChange}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
