import { ageCalc, getBMI, getBmiType } from "./helperFunctions";

import { nutritionRecsFunction } from "./summaryHelperFunctions";

import {
  hospitalDxProgNote,
  supplementSplitter,
  foodProgressNote,
  textAreaDivider,
  labProgNote,
  ubwRangeCheckerProgNote,
  wtChangeProgressNote,
  nutNeedsProgNote,
  nutritionDxProgNote,
  nutritionRxProgNote,
} from "./progressNoteHelperFunctions";

export const wrProgressTemplate = (data) => {
  const dateOfBirth = data.age;

  const allergy = {
    type: data.foodAllergy.map((item) => item.allergyname).join("/"),
    name: "NKFA",
  };

  const intolerance = {
    type: data.foodIntolerance.map((item) => item.intolerancename).join("/"),
    name: "NKFI",
  };
  const [room, unit] = data.room.split("-");
  const pmhx = { type: data.pmhx, name: "PAST MEDICAL HISTORY" };
  const meds = { type: data.meds, name: "NUTRITION RELATED MEDICATIONS" };
  const wtKg = data.wt / 2.2;

  let weightCollection;
  if (!data.wt && data.weightOrder !== "d/c") {
    weightCollection = `
    Current Wt(lbs): No Weight at this time<br>
    Current Wt (kg): unable to obtain without weight<br>
    BMI: unable to obtain without weight<br>`;
  } else if (data.weightOrder === "d/c") {
    weightCollection = `
    Current Wt: Weights have been discontinued<br>`;
  } else if (data.wt) {
    weightCollection = `
    Current Wt (lbs): ${data.wt} lbs<br>
    Current Wt(kg): ${wtKg.toFixed(1)} kg<br>
    BMI: ${getBMI(data.ht, data.wt).toFixed(2)} (${getBmiType(
      data.ht,
      data.wt
    )})<br>`;
  }

  const progressNoteTemplate = `
      <p>
      Assessment Type: ${data.assessType} Nutrition Note<br>
      ${hospitalDxProgNote(data) && `${hospitalDxProgNote(data)}`}
      ${data.admitLocal && `Admitted From: ${data.admitLocal}<br>`}
      ${data.clientType}: ${data.lastName}, ${data.firstName}<br>
      Date of Birth: ${
        dateOfBirth
          ? `${new Date(dateOfBirth).toLocaleDateString("en")} (${ageCalc(
              dateOfBirth
            )} years old)<br>`
          : "Not a Valid Date<br>"
      }
      Location: ${unit}<br>
      Room Number: ${room}-P<br>
      ${
        data.theraDiet &&
        data.texture &&
        data.liquids &&
        `Diet: ${data.theraDiet} ${data.texture && `, ${data.texture}`} ${
          data.liquids && `, ${data.liquids}`
        }<br>`
      }
      ${
        data.prevAssessType &&
        `Last Assessment: ${
          data.prevAssessType
        } <br> Last Assessment Date: ${new Date(
          data.prevDate
        ).toLocaleDateString("en-US", { timeZone: "UTC" })}<br>`
      }
      ${
        data.assessType === "Quarterly" || data.assessType === "Annual"
          ? `&#10070 Date Diet Ordered: (${new Date(
              data.dietstartdate
            ).toLocaleDateString("en")}) &#10132 ${
              new Date(data.dietstartdate) > new Date(data.prevDate)
                ? "Changed"
                : "No Change"
            } from last Assessment<br>`
          : ""
      }
      ${data.portionSize && `Portion Size: ${data.portionSize}<br>`}
      </p>
      ${supplementSplitter(data)}
      <p>
      Meal Intake: ${data.foodIntake}<br>
      ${supplementSplitter(data) && `Supplement Intake: ${data.supIntake}<br>`}
      Food Allergies: ${foodProgressNote(allergy)}<br>
      Food Intolerances: ${foodProgressNote(intolerance)}<br>
      Upper Dentition: ${data.upperDent}<br>
      Lower Dentition: ${data.lowerDent}<br>
      Adaptive Equipment: ${!data.adaptEquip ? "None" : data.adaptEquip}<br>
      Skin Integrity: ${data.skin}<br>
      OTHER DIET HISTORY INFORMATION:<br>
      ${data.currSummary && `${data.currSummary}<br>`}
      </p>
      ${textAreaDivider(pmhx)}
      ${textAreaDivider(meds)}
      ${labProgNote(data)}
      <p>
      ---------------------------<br>
      ${weightCollection}
      ${data.ht && `Height: ${data.ht} inches<br>`}
      ${ubwRangeCheckerProgNote(data)}
      ${wtChangeProgressNote(data)}
      ${nutNeedsProgNote(data)}
      ${nutritionDxProgNote(data)}
      ${nutritionRxProgNote(data)}
      ${
        nutritionRecsFunction(data)
          ? `---------------------------<br>${nutritionRecsFunction(data)}`
          : ""
      }
      </p>
      <br>
  `;

  return progressNoteTemplate;
};
