import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//hooks
import usePersist from "../hooks/usePersist";

import { useDispatch } from "react-redux";
import { useLoginMutation } from "../app/api/authAPISlice";

//page styles
import globalStyles from "../index.module.css";
import styles from "../css/Login.module.css";
import logo from "../images/apple.png";
import { setAuthentication } from "../app/auth/twoFaSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();

  const [login] = useLoginMutation();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const { data } = await login({ username, password, location }).unwrap();
      dispatch(setAuthentication(true));
      setUsername("");
      setPassword("");
      navigate("/verify", { state: data.user.id });
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Username or Password or Location");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
    }
  };

  const handleUserInput = (e) => setUsername(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleLocInput = (e) => setLocation(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  return (
    <div className={globalStyles.body_wrapper}>
      <div className={styles.login_wrapper_outer}>
        <div className={styles.login_wrapper_inner}>
          <div className={styles.login_title}>
            <img src={logo} alt="Logo" className={styles.login_logo} />
            <h3 className={styles.login_name}>Nutrition Toolbox</h3>
          </div>
          <div className={styles.login_inputs}>
            <span>Username:</span>
            <input
              type="text"
              ref={userRef}
              value={username}
              id="userName"
              onChange={handleUserInput}
              autoComplete="off"
              required
            />
            <span>Password:</span>
            <input
              type="password"
              id="password"
              onChange={handlePwdInput}
              value={password}
              required
            />
          </div>
          <div className={styles.login_select}>
            <span>Location:</span>
            <select name="location" value={location} onChange={handleLocInput}>
              <option></option>
              <option value="bwt">BWT</option>
              <option value="wr">WR</option>
            </select>
          </div>
          <button className={styles.login_button} onClick={handleClick}>
            Login
          </button>
          <p>{errMsg}</p>
          <label htmlFor="persist">
            <input
              type="checkbox"
              id="persist"
              onChange={handleToggle}
              checked={persist}
            />
            Trust This Device
          </label>
        </div>
      </div>
    </div>
  );
};

export default Login;
