import { useState } from "react";

//styles
import styles from "../../css/Settings.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const EditUser = ({ user, handleEditClosed }) => {
  const [status, setStatus] = useState(user.active);
  const [twoFA, setTwoFA] = useState(user.enable2fa);

  const handleStatus = (e) => setStatus(e.target.value);
  const handleTwoFA = (e) => setTwoFA(e.target.value);

  return (
    <div className={styles.settings_edit_user}>
      <div className={styles.settings_edit_user_top_row}>
        <h4>Edit User</h4>
        <FontAwesomeIcon
          icon={faXmark}
          size="md"
          onClick={handleEditClosed}
          style={{
            paddingRight: "10px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.settings_edit_user_inner_content}>
        <h7>Username: </h7>
        <span className={styles.settings_edit_username}>{user.username}</span>
        <h7>Set Active Status: </h7>
        <select value={status} onChange={handleStatus}>
          <option></option>
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>
        </select>
        {user.enable2fa && (
          <>
            <h7>Set 2FA Status: </h7>
            <select value={twoFA} onChange={handleTwoFA}>
              <option></option>
              <option value={true}>Enabled</option>
              <option value={false}>Disabled</option>
            </select>
          </>
        )}
      </div>
    </div>
  );
};

export default EditUser;
